import { CircularProgress, makeStyles, Typography, Button, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import GridExportBar from '../../Components/Grid/GridExportBar';
import shortDateFormat from '../../Helpers/DateFormatter';
import useDriverService from '../../Services/useDriverService';
import DriverData from '../../shared/types/DriverData';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    }
}));

const columns: GridColDef[] = [
    {
        field: 'scannedPackages',
        headerName: 'Packages',
        flex: 0.5,
        valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
            const packages = getValue(id, 'scannedPackages') as string[]
            return `${packages.length}`
        }
    },
    {
        field: 'unlockTimestamp',
        headerName: 'Unlock Timestamp',
        flex: 0.5,
        valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
            const unlockDate = getValue(id, 'unlockTimestamp') as string
            if (unlockDate) {
                return shortDateFormat(new Date(unlockDate))
            }
            return 'None';
        }
    },
    {
        field: 'dropLocations',
        headerName: 'Drop Locations',
        flex: 0.3,
        valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
            const cell = getValue(id, 'dropLocations') as any[]
            return cell.length
        }
    }
];

const DriverDetails: React.FunctionComponent = () => {
    const { driverId } = useParams<{driverId: string}>();
    const classes = useStyles();
    const history = useHistory();

    const drivers = useSelector((store: RootState) => store.driver);

    const [isLoading, setIsLoading] = useState(true);
    const driverService = useDriverService();
    const [driver, setDriver] = useState<DriverData | null>(null);

    useEffect(() => {
        const curDriver = drivers.find((driver) => driver.id === driverId);
        if (curDriver) {
            setDriver(curDriver);
        } else {
            history.push('/driver');
        }
    }, [drivers, driverId]);

    useEffect(() => {
        setIsLoading(true);
        async function loadDriverLog() {
            try {
                await driverService.getDriverLogs(driverId);
            } finally {
                setIsLoading(false);
            }
        }
        loadDriverLog();
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h4" color="inherit">
                    {`Driver: ${driver?.name}`}
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      color="primary"
                      onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Door" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {driver?.doorName}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Name" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {driver?.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Locker" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {driver?.lockerId}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                      classes={{ root: classes.gridHeader }}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={isLoading}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      pageSize={10}
                      rows={driver && driver?.driverLogs ? driver.driverLogs : []}
                      onRowClick={(selectedRow) => {
                            history.push(`/driver/${driverId}/${selectedRow.getValue(selectedRow.id, 'id')}`)
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DriverDetails;
