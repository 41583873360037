import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import React, { useState } from 'react';

const InputDialog: React.FunctionComponent<InputDialogProps> = (
    {
        open,
        setOpen,
        successCallback,
        title,
        message,
        placeholder
    }: InputDialogProps
) => {
    const [data, setData] = useState<string>();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = () => {
        setOpen(false);
        if (data) {
            successCallback(data);
        }
    };

    return (
        <Dialog
          open={open}
          onClose={handleClose}
          disableBackdropClick
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    {message}
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Data"
                      type="text"
                      fullWidth
                      placeholder={placeholder}
                      onChange={(e) => setData(e.target.value)}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface InputDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    successCallback: (data: string) => void,
    title: string,
    message: string,
    placeholder?: string
}

InputDialog.defaultProps = {
    placeholder: ''
};

export default InputDialog;
