/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import LockerData from '../../shared/types/LockerData'
import LockState from '../../shared/types/LockState';
import { RLLockData } from '../../shared/types/RLLocksResponse';
import LockerIdentifier from '../../types/LockerIdentifier';

type lockersState = {
    lockers: LockerData[]
}

const initialState: lockersState = {
    lockers: []
};

export enum lockerActions {
    LoadState = 'LockerActions_LoadState',
    UpdateLocker = 'LockerActions_UpdateLocker',
    ResetPackages = 'LockerActions_ResetPackages',
    RefillLabels = 'LockerActions_RefillLabels'
}

const getDoorState = (doorState: RLLockData): number => {
    if (doorState?.attributes.connected) {
        if (doorState?.attributes.state === 'unlocked') {
            return LockState.unlocked;
        }
        return LockState.locked;
    }
    return LockState.offline;
};

export default function lockerReducer(state = initialState, action: AnyAction): lockersState {
    if (action.type === lockerActions.LoadState) {
        const lockerData: LockerData[] = action.payload;
        return {
            lockers: lockerData
        }
    }

    if (action.type === lockerActions.UpdateLocker) {
        const lockerData: RLLockData = action.payload;
        return {
            lockers: state.lockers?.map((locker) => {
                const newLocker = locker;
                const workingIndex = locker.doors.findIndex(
                    (door) => door.slotLockId === lockerData.id || door.doorLockId === lockerData.id
                );
                if (workingIndex !== -1) {
                    if (newLocker.doors[workingIndex].slotLockId === lockerData.id) {
                        newLocker.doors[workingIndex].slotLockState = getDoorState(lockerData);
                    } else {
                        newLocker.doors[workingIndex].doorLockState = getDoorState(lockerData);
                    }
                }
                return newLocker;
            })
        }
    }

    if (action.type === lockerActions.ResetPackages) {
        const lockerData: LockerIdentifier = action.payload;
        return {
            lockers: state.lockers?.map((locker) => {
                const updatingLocker = locker;
                const workingIndex = locker.doors.findIndex(
                    (door) => door.slotLockId === lockerData.slotLockId && locker.lockerId === lockerData.lockerId
                );
                if (workingIndex !== -1) {
                    updatingLocker.doors[workingIndex].packageCount = 0;
                }
                return updatingLocker;
            })
        }
    }

    if (action.type === lockerActions.RefillLabels) {
        const refillData = action.payload;
        return {
            lockers: state.lockers?.map((locker) => {
                const updatingLocker = locker;
                if (locker.lockerId === refillData.lockerId) {
                    updatingLocker.labelsRemaining = refillData.labelCount;
                }
                return updatingLocker;
            })
        }
    }

    return state;
}
