import React, { useState } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { CircularProgress, makeStyles } from '@material-ui/core';
import colors from '../../Styles/Colors';
import LockState from '../../shared/types/LockState';

const useStyles = makeStyles(() => ({
    button: {
        paddingTop: '3px',
        border: '1px solid white',
        borderRadius: '10px',
        cursor: 'pointer',
        height: '40px',
        color: colors.white
    },
    buttonLocked: {
        backgroundColor: colors.errorRed
    },
    buttonUnlocked: {
        backgroundColor: colors.lightGreen
    },
    buttonOffline: {
        backgroundColor: colors.darkGray,
        fontSize: '20px',
        cursor: 'not-allowed'
    }
}));

const LockButton: React.FunctionComponent<LockButtonProps> = ({ lockState, classes, onClick }: LockButtonProps) => {
    const localClasses = useStyles();
    const [loading, setLoading] = useState(false);

    const getButtonClasses = () => {
        if (lockState === LockState.locked) {
            return localClasses.buttonLocked;
        }

        if (lockState === LockState.unlocked) {
            return localClasses.buttonUnlocked;
        }

        return localClasses.buttonOffline;
    };

    const runAction = async () => {
        if (lockState !== LockState.offline) {
            setLoading(true);
            await onClick();
            setLoading(false);
        }
    }

    const getButtonContent = () => {
        if (loading) {
            return <CircularProgress size={24} />
        }

        if (lockState === 0) {
            return <LockIcon />;
        }

        if (lockState === 1) {
            return <LockOpenIcon />;
        }

        return 'Offline';
    };

    return (
        <button
          type="button"
          onClick={runAction}
          className={`${localClasses.button} ${getButtonClasses()} ${classes}`}
        >
            {getButtonContent()}
        </button>
    );
};

interface LockButtonProps {
    lockState: number,
    classes?: string,
    onClick: () => Promise<any>
}

LockButton.defaultProps = {
    classes: ''
};

export default LockButton;
