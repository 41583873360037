import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = (
    {
        open,
        setOpen,
        successCallback,
        title,
        message,
        showNo,
        successText
    }: ConfirmDialogProps
) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = () => {
        setOpen(false);
        successCallback();
    };

    return (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          disableBackdropClick
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary" style={{ wordWrap: 'break-word' }}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    showNo
                    && (
                    <Button onClick={handleClose} color="secondary" variant="contained">
                        No
                    </Button>
                    )
                }
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    {successText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface ConfirmDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    successCallback: () => void,
    title: string,
    message: string,
    showNo?: boolean,
    successText?: string
}

ConfirmDialog.defaultProps = {
    showNo: true,
    successText: 'Yes'
}

export default ConfirmDialog;
