import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridSortItem, GridValueFormatterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import GridExportBar from '../../Components/Grid/GridExportBar';
import shortDateFormat from '../../Helpers/DateFormatter';
import useFeedbackService from '../../Services/useFeedbackService';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const columns: GridColDef[] = [
    {
        field: 'rating',
        headerName: 'Rating',
        flex: 1,
        valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
            const rating = getValue(id, 'rating') as string
            if (rating) {
                return rating;
            }
            return 'Initial Feedback'
        }
    },
    {
        field: 'comment',
        headerName: 'Comment',
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        renderCell: ({ id, getValue }: GridCellParams) => {
            const createdDate = getValue(id, 'date') as string
            return shortDateFormat(new Date(createdDate));
        }
    }
];

const FeedbackDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const feedback = useSelector((store: RootState) => store.feedback);
    const feedbackService = useFeedbackService();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [tableSort, setTableSort] = useState<GridSortItem[]>([{ field: 'date', sort: 'desc' }]);

    useEffect(() => {
        async function loadFeedbackData() {
            await feedbackService.getFeedbackData();
            setLoading(false);
        }

        loadFeedbackData();
    }, []);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                Feedback
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => `${row.date}${row.userId}`}
                      classes={{ root: classes.gridHeader }}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      components={{
                          Toolbar: GridExportBar
                      }}
                      sortModel={tableSort}
                      onSortModelChange={(sortModel) => setTableSort(sortModel)}
                      loading={loading}
                      pageSize={10}
                      rows={feedback}
                      onRowClick={(selectedRow) => {
                        history.push(`/user/${selectedRow.getValue(selectedRow.id, 'userId')}`)
                    }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default FeedbackDash;
