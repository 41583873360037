import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import useDiscountService from '../../Services/useDiscountService';
import { RootState } from '../../Store/mainStore';
import shortDateFormat from '../../Helpers/DateFormatter';
import DiscountDialog from '../../Components/Dialogs/DiscountDialog';
import DiscountData from '../../shared/types/DiscountData';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    }
}));

const DiscountDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const discountService = useDiscountService();
    const discounts = useSelector((store: RootState) => store.discount);

    const [editingDiscount, setEditingDiscount] = useState<DiscountData | undefined>();
    const [showDiscountDialog, setShowDiscountDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function loadDiscounts() {
            await discountService.getDiscountData();
            setIsLoading(false);
        }
        loadDiscounts();
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h4" color="inherit">
                    Discounts
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                          setEditingDiscount(undefined);
                          setShowDiscountDialog(!showDiscountDialog);
                      }}
                    >
                        New
                    </Button>
                </Typography>
            </div>
            <Grid container spacing={5}>
                {discounts.discounts.map((discount) => (
                    <Grid item lg={4} md={6} xs={12} key={`${discount.company}${discount.name}`}>
                        <Card style={{ height: '100%' }}>
                            <CardHeader
                              title={discount.company}
                              action={(
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setEditingDiscount(discount);
                                        setShowDiscountDialog(!showDiscountDialog)
                                    }}
                                  >
                                      <EditIcon />
                                  </IconButton>
                            )}
                            />
                            <CardContent>
                                <Grid container spacing={5} style={{ height: '100%' }}>
                                    <Grid item xs={12} style={{ height: '100%', textAlign: 'center' }}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                                            {discount.name}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Codes Remaining:
                                            {` ${discount.discountCodes.length - discount.discountCodes.filter((x) => x.ownedBy).length}/${discount.discountCodes.length}`}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Start Date:
                                            {` ${shortDateFormat(discount.startDate)}`}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            End Date:
                                            {` ${shortDateFormat(discount.endDate)}`}
                                        </Typography>
                                        <img style={{ marginTop: '10px', height: '100px', objectFit: 'contain' }} src={discount.imageUrl} alt={discount.name} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                  ))}
            </Grid>
            <DiscountDialog
              open={showDiscountDialog}
              setOpen={setShowDiscountDialog}
              successCallback={() => console.log('done')}
              discount={editingDiscount}
            />
        </>
    )
}

export default DiscountDash;
