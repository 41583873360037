/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import DiscountData from '../../shared/types/DiscountData';

type discountState = {
    discounts: DiscountData[]
}

const initialState: discountState = {
    discounts: []
};

export enum discountActions {
    LoadState = 'DiscountActions_LoadState',
    AddDiscount = 'DiscountActions_AddDiscount',
    UpdateDiscount = 'DiscountActions_UpdateDiscount'
}

export default function discountReducer(state: discountState = initialState, action: AnyAction): discountState {
    if (action.type === discountActions.LoadState) {
        const currentDiscounts: DiscountData[] = action.payload;
        return {
            discounts: currentDiscounts
        };
    }

    if (action.type === discountActions.AddDiscount) {
        const currentDiscount: DiscountData = action.payload;
        const newState = { ...state };
        newState.discounts.push(currentDiscount);
        return newState;
    }

    if (action.type === discountActions.UpdateDiscount) {
        const currentDiscount: DiscountData = action.payload;

        const newDiscounts = state.discounts.map((discount) => {
            if (discount._id.toString() === currentDiscount._id.toString()) {
                return {
                    name: currentDiscount.name,
                    company: currentDiscount.company,
                    endDate: currentDiscount.endDate,
                    imageUrl: currentDiscount.imageUrl,
                    startDate: currentDiscount.startDate,
                    _id: currentDiscount._id,
                    discountCodes: discount.discountCodes
                };
            }

            return discount;
        });

        return {
            ...state,
            discounts: newDiscounts
        };
    }

    return state;
}
