import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import colors from '../../Styles/Colors';
import useUserService from '../../Services/useUserService';
import { RootState } from '../../Store/mainStore';
import SubscriptionType from '../../shared/types/SubscriptionTypes';
import GridExportBar from '../../Components/Grid/GridExportBar';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const UserDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const userService = useUserService();
    const userList = useSelector((store: RootState) => store.user.userList);
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);

    const columns: GridColDef[] = [
        {
            field: 'email',
            headerName: 'Email',
            flex: 0.5
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 0.5
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 0.5
        },
        {
            field: 'returnCredits',
            headerName: 'Return Credits',
            flex: 0.5
        },
        {
            field: 'subscriptionTier',
            headerName: 'Subscription',
            flex: 0.5,
            renderCell: ({ id, getValue }: GridRenderCellParams) => {
                const subTier = getValue(id, 'subscriptionTier') as SubscriptionType;
                if (subTier === SubscriptionType.CartDealer) {
                    return <span>Cart Dealer</span>;
                }

                if (subTier === SubscriptionType.FreeTrial) {
                    return <span>Free Trial</span>;
                }

                return <span>Scoopreme</span>;
            },
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const subTier = getValue(id, 'subscriptionTier') as SubscriptionType;
                if (subTier === SubscriptionType.CartDealer) {
                    return 'Cart Dealer';
                }

                if (subTier === SubscriptionType.FreeTrial) {
                    return 'Free Trial';
                }

                return 'Scoopreme';
            }
        },
        {
            field: 'lockerName',
            headerName: 'Locker',
            flex: 1
        },
        {
            field: 'returnCount',
            headerName: 'Returns',
            flex: 0.5
        }
    ];

    useEffect(() => {
        async function loadUserDetails() {
            await userService.getUserData();
            setLoading(false);
        }

        loadUserDetails();
    }, []);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                {`Users | ${userList.length}  `}
            </Typography>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <DataGrid
                      classes={{
                          columnHeader: classes.gridHeader
                      }}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      rows={userList}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={loading}
                      pageSize={10}
                      onRowClick={(selectedRow) => {
                        history.push(`/user/${selectedRow.getValue(selectedRow.id, 'id')}`)
                      }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default UserDash;
