import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { muiDateFormat } from '../../Helpers/DateFormatter';
import useDiscountService from '../../Services/useDiscountService';
import DiscountData from '../../shared/types/DiscountData';

const DiscountDialog: React.FunctionComponent<DiscountDialogProps> = (
    {
        open,
        setOpen,
        successCallback,
        discount
    }: DiscountDialogProps
) => {
    const [newDiscount, setNewDiscount] = useState<any>(discount);
    const [codeType, setCodeType] = useState<string>('code');
    const [codes, setCodes] = useState<string[]>([]);

    const discountService = useDiscountService();

    useEffect(() => {
        if (discount) {
            setNewDiscount(discount);
        } else {
            console.log('Clear');
            setNewDiscount({});
        }
    }, [discount]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = async () => {
        setOpen(false);

        const saveObject: DiscountData = {
            _id: newDiscount._id as string,
            company: newDiscount.company,
            endDate: newDiscount.endDate,
            startDate: newDiscount.startDate,
            imageUrl: newDiscount.imageUrl,
            name: newDiscount.name,
            discountCodes: codes.map((code) => ({
                discountId: newDiscount._id as string,
                codeText: codeType === 'code' ? code : undefined,
                codeUrl: codeType === 'link' ? code : undefined
            }))
        };

        if (!saveObject._id) {
            await discountService.createDiscount(saveObject);
        } else {
            await discountService.updateDiscount(saveObject);
        }

        successCallback();
    };

    return (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          disableBackdropClick
        >
            <DialogTitle>{discount ? 'Edit Discount' : 'New Discount'}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    <Grid container style={{ width: '500px' }} spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Company"
                              value={newDiscount?.company || ''}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => setNewDiscount({ ...newDiscount, company: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Discount Name"
                              value={newDiscount?.name || ''}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => setNewDiscount({ ...newDiscount, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Image Url"
                              value={newDiscount?.imageUrl || ''}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => setNewDiscount({ ...newDiscount, imageUrl: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                              type="datetime-local"
                              fullWidth
                              label="Start Date"
                              value={muiDateFormat(new Date(newDiscount?.startDate))}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => setNewDiscount({ ...newDiscount, startDate: new Date(e.target.value) })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                              type="datetime-local"
                              fullWidth
                              label="End Date"
                              value={muiDateFormat(new Date(newDiscount?.endDate))}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => setNewDiscount({ ...newDiscount, endDate: new Date(e.target.value) })}
                            />
                        </Grid>
                        {
                            !discount
                            && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Discount Type</FormLabel>
                                        <RadioGroup
                                          row
                                          name="discountType"
                                          onChange={(e) => setCodeType(e.target.value)}
                                        >
                                            <FormControlLabel control={<Radio />} label="Codes" value="code" />
                                            <FormControlLabel control={<Radio />} label="Links" value="link" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      rowsMax={5}
                                      multiline
                                      fullWidth
                                      label="Codes / Links (One per line)"
                                      onChange={(e) => setCodes(e.target.value.split('\n').filter((line) => line.length > 0))}
                                    />
                                </Grid>
                            </>
                        )
                    }
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Close
                </Button>
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface DiscountDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    successCallback: () => void,
    discount?: DiscountData
}

DiscountDialog.defaultProps = {
    discount: undefined
}

export default DiscountDialog;
