/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import FeedbackData from '../../shared/types/FeedbackData';

const initialState: FeedbackData[] = [];

export enum feedbackActions {
    LoadState = 'FeedbackActions_LoadState'
}

export default function feedbackReducer(state: FeedbackData[] = initialState, action: AnyAction): FeedbackData[] {
    if (action.type === feedbackActions.LoadState) {
        const feedbackList: FeedbackData[] = action.payload;
        return feedbackList;
    }

    return state;
}
