import { useAuth0 } from '@auth0/auth0-react';
import ApiResponse from '../shared/types/ApiResponse';
import DropDownResponse from '../shared/types/DropDownResponse';

interface dropDownServiceType {
    getDropDownData: (key: string) => Promise<DropDownResponse | null>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useDropDownService: () => dropDownServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getDropDownData = async (key: string) => {
        const response = await fetch(`${apiUrl}/api/dropdown/${key}`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<DropDownResponse>;

        if (results.success && results.data) {
            return results.data;
        }

        return null;
    };

    return { getDropDownData };
};

export default useDropDownService;
