import React from 'react';
import { Drawer, Grid, Typography, makeStyles } from '@material-ui/core';
import ScoopLogo from '../../Assets/ScoopLogo.svg';
import NavLinks from './NavLinks';

const useStyles = makeStyles(() => ({
    listItemText: {
        paddingTop: '5px'
    },
    headerContainer: {
        paddingBottom: '30px'
    }
}));

const Sidebar: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Drawer
          variant="permanent"
          open
          anchor="left"
          PaperProps={{ style: { width: '300px' } }}
        >
            <Grid container justify="center" alignItems="center" wrap="nowrap" className={classes.headerContainer}>
                <Typography align="right" variant="h4" color="textSecondary" display="inline">
                    Admin
                </Typography>
                <img src={ScoopLogo} alt="Scoop Logo" style={{ display: 'inline', width: '50px', marginLeft: 10 }} />
            </Grid>
            <NavLinks />
        </Drawer>
    );
};

export default Sidebar;
