/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import DetailedUserResponse from '../../shared/types/DetailedUserResponse';
import SubscriptionType from '../../shared/types/SubscriptionTypes';
import { UserObject } from '../../shared/types/UserResponse';
import { UserStore } from '../../types/UserStore';

const initialState: UserStore = {
    userList: [],
    currentUserDetails: {
        firstName: '',
        id: '',
        lastName: '',
        lockerId: '',
        lockerName: '',
        returnCredits: 0,
        subscriptionTier: SubscriptionType.CartDealer,
        activeReturns: 0,
        totalReturns: 0,
        returns: [],
        canUpgradeToFreeTrial: false
    }
};

export enum userActions {
    LoadState = 'UserActions_LoadState',
    LoadUserDetails = 'UserActions_LoadDetails',
    UpdateReturnCredits = 'UserActions_UpdateCredits',
    UpgradeUserToFreeTrial = 'UserActions_UpgradeToFreeTrial'
}

export default function dashboardReducer(state: UserStore = initialState, action: AnyAction): UserStore {
    if (action.type === userActions.LoadState) {
        const userList: UserObject[] = action.payload;
        return {
            userList,
            currentUserDetails: state.currentUserDetails
        };
    }

    if (action.type === userActions.LoadUserDetails) {
        const userDetails: DetailedUserResponse = action.payload;
        return {
            userList: state.userList,
            currentUserDetails: userDetails
        }
    }

    if (action.type === userActions.UpdateReturnCredits) {
        const returnCredits: number = action.payload;
        return {
            userList: state.userList,
            currentUserDetails: {
                ...state.currentUserDetails,
                returnCredits
            }
        }
    }

    if (action.type === userActions.UpgradeUserToFreeTrial) {
        // Update the current users subscription tier to free trial
        return {
            userList: state.userList,
            currentUserDetails: {
                ...state.currentUserDetails,
                subscriptionTier: SubscriptionType.FreeTrial,
                canUpgradeToFreeTrial: false
            }
        }
    }

    return state;
}
