/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import ReturnDetail from '../../shared/types/ReturnDetail';

type returnState = {
    returns: ReturnDetail[]
}

const initialState: returnState = {
    returns: []
};

export enum returnActions {
    LoadState = 'ReturnActions_LoadState'
}

export default function returnReducer(state: returnState = initialState, action: AnyAction): returnState {
    if (action.type === returnActions.LoadState) {
        const returnList: ReturnDetail[] = action.payload;
        return {
            returns: returnList
        };
    }

    return state;
}
