import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import ReturnDetail from '../shared/types/ReturnDetail';
import { returnActions } from '../Store/Reducers/returnReducer';

interface returnServiceType {
    getReturnData: () => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useReturnService: () => returnServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getReturnData: () => Promise<boolean> = async () => {
        const response = await fetch(`${apiUrl}/api/return`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<ReturnDetail[]>;
        console.log(results);
        if (results.success) {
            dispatch({
                type: returnActions.LoadState,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    return { getReturnData };
};

export default useReturnService;
