import { Button,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Input,
    Checkbox,
    ListItemText } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useACSAccessUserService from '../../Services/useACSAccessUserService';
import { RootState } from '../../Store/mainStore';
import { AccessUser } from '../../types/AccessUser';

const AccessUserDialog: React.FunctionComponent<AccessUserDialogProps> = (
    {
        open,
        setOpen,
        editUser
    }: AccessUserDialogProps
) => {
    const [newAccessUser, setNewAccessUser] = useState<AccessUser>({
        _id: '',
        accessCode: '',
        locks: [],
        name: '',
        retryTimeout: 300
    });

    const accessLocks = useSelector((state: RootState) => state.accessLock)
    const [submitted, setSubmitted] = useState<any>(false);

    const acsUserService = useACSAccessUserService();

    useEffect(() => {
        if (open) {
            setSubmitted(false);
            if (editUser) {
                setNewAccessUser(editUser);
            } else {
                setNewAccessUser({
                    _id: '',
                    accessCode: '',
                    locks: [],
                    name: '',
                    retryTimeout: 300,
                    currentAttempts: undefined,
                    endTime: undefined,
                    maxAttempts: undefined,
                    startTime: undefined
                });
            }
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = async () => {
        setSubmitted(true);
        if (
            newAccessUser.name
            && newAccessUser.locks && newAccessUser.locks.length > 0
            && newAccessUser.retryTimeout
            && newAccessUser.accessCode
        ) {
            if (editUser) {
                const results = await acsUserService.updateAccessUser(newAccessUser);
                if (results) {
                    setOpen(false);
                }
            } else {
                const results = await acsUserService.saveAccessUser(newAccessUser);
                if (results) {
                    setOpen(false);
                }
            }
        }
    };

    return (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          disableBackdropClick
        >
            <DialogTitle>{editUser ? 'Edit Access User' : 'New Access User'}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    <Grid container style={{ maxWidth: '500px' }} spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAccessUser.name || newAccessUser.name.length === 0)}
                              label="Name"
                              InputLabelProps={{ shrink: true }}
                              value={newAccessUser.name}
                              onChange={(e) => setNewAccessUser({ ...newAccessUser, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAccessUser.accessCode)}
                              label="Access Code"
                              InputLabelProps={{ shrink: true }}
                              value={newAccessUser.accessCode}
                              onChange={(e) => setNewAccessUser({ ...newAccessUser, accessCode: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAccessUser.retryTimeout)}
                              label="Retry Timeout (seconds)"
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              value={newAccessUser.retryTimeout}
                              onChange={(e) => setNewAccessUser({ ...newAccessUser, retryTimeout: parseInt(e.target.value, 10) })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    ACS Locks
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  multiple
                                  fullWidth
                                  error={submitted && (!newAccessUser.locks || newAccessUser.locks.length === 0)}
                                  value={newAccessUser.locks}
                                  renderValue={
                                      (selected) => (selected as string[]).map(
                                          (x) => accessLocks?.find((y) => y._id === x)?.name
                                        ).join(', ')
                                    }
                                  onChange={(e) => setNewAccessUser({ ...newAccessUser, locks: e.target.value as string[] })}
                                >
                                    {accessLocks.map((lock) => (
                                        <MenuItem key={lock._id} value={lock._id}>
                                            <Checkbox checked={newAccessUser.locks.indexOf(lock._id) > -1} />
                                            <ListItemText primary={lock.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimePicker
                              fullWidth
                              label="Start Time"
                              InputLabelProps={{ shrink: true }}
                              clearable
                              format="MMM do yyyy, HH:mm aaa"
                              value={newAccessUser?.startTime || null}
                              onChange={(e) => e && setNewAccessUser({ ...newAccessUser, startTime: e })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimePicker
                              fullWidth
                              label="End Time"
                              clearable
                              format="MMM do yyyy, HH:mm aaa"
                              InputLabelProps={{ shrink: true }}
                              value={newAccessUser?.endTime || null}
                              onChange={(e) => e && setNewAccessUser({ ...newAccessUser, endTime: e })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Max Attempts"
                              helperText="Leave blank for unlimited"
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              value={newAccessUser.maxAttempts}
                              onChange={(e) => setNewAccessUser({ ...newAccessUser, maxAttempts: parseInt(e.target.value, 10) })}
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Close
                </Button>
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AccessUserDialog.defaultProps = {
    editUser: undefined
}

interface AccessUserDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    editUser?: AccessUser
}

export default AccessUserDialog;
