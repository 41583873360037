import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import LockerCard from '../../Components/Cards/LockerCard';
import useLockerService from '../../Services/useLockerService';
import { RLLockData } from '../../shared/types/RLLocksResponse';
import { RootState } from '../../Store/mainStore';
import { lockerActions } from '../../Store/Reducers/lockerReducer';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    }
}));

const LockerDash: React.FunctionComponent = () => {
    const classes = useStyles();

    const lockers = useSelector((store: RootState) => store.locker.lockers);
    const dispatch = useDispatch();
    const lockerService = useLockerService();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function loadLockerData() {
            await lockerService.getLockers();
            setLoading(false);
        }

        loadLockerData();
    }, []);

    const UpdateLockerData = (updateInfo: RLLockData) => {
        dispatch({
            type: lockerActions.UpdateLocker,
            payload: updateInfo
        });
    };

    if (loading) {
        return <CircularProgress />
    }

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                Lockers
            </Typography>
            <Grid container spacing={5}>
                {
                    lockers?.sort((x, y) => (x.lockerId < y.lockerId ? 1 : 0)).map((locker) => (
                        <Grid key={locker.lockerId} item xl={4} lg={6} xs={12}>
                            <LockerCard Locker={locker} UpdateLocker={UpdateLockerData} />
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
};

export default LockerDash;
