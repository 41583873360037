export enum AccessLevel {
    Admin = 'Admin',
    User = 'User'
}

export type AccessToken = {
    _id: string;
    name: string;
    level: AccessLevel;
    token?: string;
};
