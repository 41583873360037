import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import DetailedUserResponse from '../shared/types/DetailedUserResponse';
import UserResponse from '../shared/types/UserResponse';
import { userActions } from '../Store/Reducers/userReducer';

interface userServiceType {
    getUserData: () => Promise<boolean>
    getUserDetailsData: (id: string) => Promise<boolean>
    updateUserReturnCredits: (id: string, credits: number) => Promise<boolean>
    upgradeUserToFreeTrial: (userId: string) => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useUserService: () => userServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getUserData: () => Promise<boolean> = async () => {
        const response = await fetch(`${apiUrl}/api/user`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<UserResponse>;

        if (results.success) {
            dispatch({
                type: userActions.LoadState,
                payload: results.data?.users
            });

            return true;
        }
        return false;
    };

    const getUserDetailsData: (id: string) => Promise<boolean> = async (id: string) => {
        const response = await fetch(`${apiUrl}/api/user/${id}`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<DetailedUserResponse>;
        if (results.success) {
            dispatch({
                type: userActions.LoadUserDetails,
                payload: results.data
            });
            return true;
        }
        return false;
    };

    const updateUserReturnCredits: (id: string, credits: number) => Promise<boolean> = async (id: string, credits: number) => {
        const response = await fetch(`${apiUrl}/api/user/${id}/credits`, {
            method: 'PUT',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                returnCredits: credits
            })
        });
        const results = await response.json() as ApiResponse<number>;
        if (results.success) {
            dispatch({
                type: userActions.UpdateReturnCredits,
                payload: results.data
            });
            return true;
        }
        return false;
    };

    const upgradeUserToFreeTrial = async (userId: string) => {
        const response = await fetch(`${apiUrl}/api/user/trial`, {
            method: 'PATCH',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId
            })
        });

        if (response.status === 200) {
            dispatch({
                type: userActions.UpgradeUserToFreeTrial
            });
            return true;
        }

        return false;
    };

    return { getUserData, getUserDetailsData, updateUserReturnCredits, upgradeUserToFreeTrial };
};

export default useUserService;
