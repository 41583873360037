/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import DashResponse from '../../shared/types/DashResponse';

const initialState: DashResponse = {
    returnsPastMonth: [],
    lockersOnline: 0,
    totalLockers: 0,
    subscriptions: {
        cartDealer: 0,
        freeTrial: 0,
        scoopreme: 0
    }
};

export enum dashboardActions {
    LoadState = 'DashboardActions_LoadState'
}

export default function dashboardReducer(state = initialState, action: AnyAction): DashResponse {
    if (action.type === dashboardActions.LoadState) {
        const dashboardData: DashResponse = action.payload;
        return dashboardData
    }

    return state;
}
