import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const initialChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            min: 0,
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
                precision: 0
            }
        }
    }
}

const Past30DaysChart: React.FunctionComponent = () => {
    const dash = useSelector((store: RootState) => store.dashboard);

    const initialChartData = {
        labels: [''],
        datasets: [
            {
                label: 'Returns',
                data: [0],
                fill: false,
                backgroundColor: colors.mainBlue,
                borderColor: colors.mainBlue
            }
        ]
    }

    const [past30DaysData, setPast30DaysData] = useState(initialChartData);

    const getDateString = (date: Date) : string => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

    useEffect(() => {
        if (past30DaysData) {
            const newData = past30DaysData;

            const today = new Date();
            today.setDate(today.getDate() - 30);

            newData.labels = [];

            for (let i = 0; i < 30; i += 1) {
                today.setDate(today.getDate() + 1);
                newData.labels.push(getDateString(today));
            }

            if (newData.datasets?.[0]) {
                newData.datasets[0].data = dash.returnsPastMonth;
            }

            setPast30DaysData(newData);
        }
    }, [past30DaysData, dash.returnsPastMonth])

    return <Line data={past30DaysData} options={initialChartOptions} />
};

export default Past30DaysChart;
