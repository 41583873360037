import { AppBar, Drawer, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import NavLinks from './NavLinks';

const useStyles = makeStyles(() => ({
    header: {
        flex: 1,
        textAlign: 'center'
    },
    menuPaper: {
        width: '100%'
    }
}));

const MobileNavigation: React.FunctionComponent = () => {
    const classes = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <>
            <AppBar position="sticky" style={{ height: '56px' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMenuOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        Scoop Admin
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
              open={menuOpen}
              anchor="top"
              classes={{
                paper: classes.menuPaper
              }}
            >
                <NavLinks navigateCallback={() => setMenuOpen(false)} />
            </Drawer>
        </>
    );
}

export default MobileNavigation;
