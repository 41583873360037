import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import colors from '../../Styles/Colors';
import { RootState } from '../../Store/mainStore';
import GridExportBar from '../../Components/Grid/GridExportBar';
import shortDateFormat from '../../Helpers/DateFormatter';
import SocketClient from '../../Helpers/SocketClient';
import AccessUserDialog from '../../Components/Dialogs/AccessUserDialog';
import { AccessUser } from '../../types/AccessUser';
import ConfirmDialog from '../../Components/Dialogs/ConfirmDialog';
import useACSAccessUserService from '../../Services/useACSAccessUserService';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const AccessUserDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const accessUsers = useSelector((store: RootState) => store.accessUser);

    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedAccessUser, setSelectedAccessUser] = useState<AccessUser | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const acsAccessUserService = useACSAccessUserService();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5
        },
        {
            field: 'accessCode',
            headerName: 'Access Code',
            flex: 0.5
        },
        {
            field: 'startTime',
            headerName: 'Start Time',
            flex: 0.5,
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const data = getValue(id, 'startTime') as string;
                if (data) {
                    return shortDateFormat(new Date(data));
                }
                return '';
            }
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            flex: 0.5,
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const data = getValue(id, 'endTime') as string;
                if (data) {
                    return shortDateFormat(new Date(data));
                }
                return '';
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: ({ id, getValue }: GridRenderCellParams) => {
                const currentAccessUserId = getValue(id, '_id') as string;
                const currentAccessUser = accessUsers.find((user) => user._id === currentAccessUserId);

                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            setSelectedAccessUser(currentAccessUser);
                            e.stopPropagation();
                            setShowDialog(true);
                          }}
                        >
                            EDIT
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            setSelectedAccessUser(currentAccessUser);
                            e.stopPropagation();
                            setShowDeleteDialog(true);
                          }}
                        >
                            DELETE
                        </Button>
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        async function loadAccessUserDetails() {
            const socketConnection = (await SocketClient.getInstance());
            if (socketConnection?.connection && socketConnection.connection.connected) {
                socketConnection.connection.emit('configuration.sync');
            }
            setLoading(false);
        }

        loadAccessUserDetails();
    }, []);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                {`Access Credentials | ${accessUsers.length}  `}
                <Button
                  style={{ float: 'right' }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                            setSelectedAccessUser(undefined);
                            setShowDialog(true)
                      }}
                >
                    New User
                </Button>
            </Typography>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => row._id}
                      classes={{
                          columnHeader: classes.gridHeader
                      }}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      rows={accessUsers}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={loading}
                      pageSize={10}
                    />
                </Grid>
            </Grid>
            <AccessUserDialog
              open={showDialog}
              setOpen={setShowDialog}
              editUser={selectedAccessUser}
            />
            <ConfirmDialog
              open={showDeleteDialog}
              setOpen={setShowDeleteDialog}
              title="Delete Access User"
              message="Are you sure you want to delete this access user?"
              successCallback={async () => {
                  if (selectedAccessUser) {
                    await acsAccessUserService.deleteAccessUser(selectedAccessUser?._id);
                    setShowDeleteDialog(false);
                  }
                }}
            />
        </>
    );
};

export default AccessUserDash;
