import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Events from './Socket/Events';
import SocketClient from './Helpers/SocketClient';
import useAuthenticationService from './Services/useAuthenticationService';
import Theme from './Styles/Theme';
import Loading from './Views/Loading/Loading';
import Routes from './Views/Routes';

const App: React.FunctionComponent = () => {
    const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();
    const [loading, setLoading] = useState(true);
    const authenticationService = useAuthenticationService();

    const trySocketConnection = async () => {
        try {
            const socket = await SocketClient.getInstance(authenticationService);
            if (socket.connection && socket.connection.connected) {
                // After we are connected once, auto reconnection will kick in
                // no more need to manage the socket connection
                Events.configureEvents(socket.connection);
            } else {
                setTimeout(trySocketConnection, 10000);
            }
        } catch (err) {
            setTimeout(trySocketConnection, 10000);
        }
    };

    const checkUserPermissions = async () => {
        try {
            const isLoggedIn = await authenticationService.isLoggedIn();
            if (isLoggedIn) {
                // The user is now logged in
                setLoading(false);
                // Setup the socket connection
                await trySocketConnection();
            } else {
                logout({
                    returnTo: window.location.origin
                });
            }
        } catch {
            logout({
                returnTo: window.location.origin
            });
        }
    };

    useEffect(() => {
        if (!isLoading) {
            if (!isAuthenticated) {
                loginWithRedirect();
            } else {
                checkUserPermissions();
            }
        }
    }, [isLoading]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Suspense fallback={<Loading />}>
            <MuiThemeProvider theme={Theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CssBaseline />
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <BrowserRouter>
                            <Routes />
                        </BrowserRouter>
                    </div>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Suspense>
    );
};

export default App;
