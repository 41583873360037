import { makeStyles } from '@material-ui/core';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import React from 'react';
import colors from '../../Styles/Colors';

const useStyles = makeStyles(() => ({
    exportIcon: {
        marginTop: '-7px'
    },
    exportText: {
        marginTop: '5px',
        '& .MuiButton-startIcon': {
            '& .MuiSvgIcon-root': {
                color: colors.mainBlue
            }
        }
    }
}));

const GridExportBar: React.FunctionComponent = () => {
    const classes = useStyles();
    return (
        <GridToolbarContainer style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <GridToolbarExport size="large" classes={{ startIcon: classes.exportIcon, label: classes.exportText }} />
        </GridToolbarContainer>
    );
}

export default GridExportBar;
