import { createMuiTheme } from '@material-ui/core/styles';
import colors from './Colors';

const Theme = createMuiTheme({
    typography: {
        fontFamily: 'Avenir Light'
    },
    palette: {
        primary: {
            main: colors.mainBlue,
            contrastText: colors.white
        },
        secondary: {
            main: colors.lightGreen,
            contrastText: colors.white
        },
        text: {
            primary: colors.black,
            secondary: colors.white
        },
        action: {
            active: colors.white,
            selected: colors.mainBlue
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                backgroundColor: colors.mainBlue,
                fontFamily: 'Futura Light',
                fontSize: '1.2rem',
                textAlign: 'center',
                border: '1px solid black',
                boxShadow: '0px 0px 5px 2px black'
            }
        },
        MuiDrawer: {
            root: {
                width: 300
            },
            paper: {
                width: 300,
                backgroundColor: colors.mainBlue,
                paddingTop: '30px'
            },
            paperAnchorDockedLeft: {
                borderRight: 0
            }
        },
        MuiListItemIcon: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiListItem: {
            root: {
                color: colors.white,
                '&$selected': {
                    backgroundColor: colors.white,
                    color: colors.mainBlue,
                    '&:hover': {
                        backgroundColor: colors.white,
                        color: colors.mainBlue
                    },
                    '&>*>*': {
                        color: colors.mainBlue
                    }
                }
            }
        },
        MuiBottomNavigation: {
            root: {
                backgroundColor: colors.mainBlue,
                width: '100%',
                zIndex: 1000
            }
        },
        MuiBottomNavigationAction: {
            root: {
                '&$selected': {
                    backgroundColor: colors.white,
                    color: colors.mainBlue
                }
            }
        },
        MuiMenuItem: {
            root: {
                color: colors.black
            }
        },
        MuiFormLabel: {
            root: {
                color: colors.mediumGrey
            }
        },
        MuiRadio: {
            root: {
                color: colors.mainBlue,
                '&$checked': {
                    color: colors.mainBlue
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: colors.mainBlue,
                '&$checked': {
                    color: colors.mainBlue
                }
            }
        },
        MuiFormHelperText: {
            root: {
                color: colors.mediumGrey
            }
        }
    }
});

export default Theme;
