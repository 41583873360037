import { Card, CardActions, CardContent, CardHeader, Grid, makeStyles, Typography, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import LockButton from '../../Components/Buttons/LockButton';
import ConfirmDialog from '../../Components/Dialogs/ConfirmDialog';
import InputDialog from '../../Components/Dialogs/InputDialog';
import shortDateFormat from '../../Helpers/DateFormatter';
import SocketClient from '../../Helpers/SocketClient';
import useLockerService from '../../Services/useLockerService';
import DoorData from '../../shared/types/DoorData';
import LockerData from '../../shared/types/LockerData';
import LockState from '../../shared/types/LockState';
import { RLLockData } from '../../shared/types/RLLocksResponse';
import { RootState } from '../../Store/mainStore';
import { lockerActions } from '../../Store/Reducers/lockerReducer';

const useStyles = makeStyles(() => ({
    header: {
        paddingBottom: '50px'
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    centerText: {
        textAlign: 'center'
    },
    lockButton: {
        width: '90%',
        margin: '0 auto',
        display: 'block',
        marginBottom: '15px'
    }
}))

const LockerDetail: React.FunctionComponent = () => {
    const { lockerId } = useParams<{lockerId: string}>();
    const lockers = useSelector((store: RootState) => store.locker);
    const [locker, setLocker] = useState<LockerData>()

    useEffect(() => {
        setLocker(lockers.lockers.find((x) => x.lockerId === lockerId));
    }, [lockers])

    const lockerService = useLockerService();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const [resettingSlotId, setResettingSlotId] = useState<string>();
    const [showResetPackageConfirm, setShowResetPackageConfirm] = useState<boolean>(false);
    const [showRefillDialog, setShowRefillDialog] = useState<boolean>(false);

    const acsLockData = useSelector((root: RootState) => root.accessLock);

    const getLockStateFromACS = (lockId: string) => {
        if (acsLockData) {
            const acsLock = acsLockData.find((lock) => lock._id === lockId);
            if (acsLock && acsLock.isOnline) {
                return acsLock.locked ? LockState.locked : LockState.unlocked;
            }
        }
        return LockState.offline;
    };

    const UpdateLocker = (data: RLLockData) => {
        dispatch({
            type: lockerActions.UpdateLocker,
            payload: data
        });
    }

    const resetPackage = async () => {
        if (resettingSlotId) {
            await lockerService.resetPackageCount(lockerId, resettingSlotId);
        }
    }

    const refillLabels = async (data: string) => {
        try {
            const refillCount = parseInt(data, 10);
            await lockerService.refillLabels(lockerId, refillCount);
        } catch {
            console.log('Bad input');
        }
    }

    const toggleSlotLock = async (door: DoorData) => {
        if (locker?.isRemoteLock) {
            if (door.slotLockState === LockState.locked) {
                const results = await lockerService.unlockLock(door.slotLockId);
                if (results.success && results.data) {
                    UpdateLocker(results.data);
                }
            } else if (door.slotLockState === LockState.unlocked) {
                const results = await lockerService.lockLock(door.slotLockId);
                if (results.success && results.data) {
                    UpdateLocker(results.data);
                }
            }
        } else if (getLockStateFromACS(door.slotLockId) === LockState.locked) {
            const client = await SocketClient.getInstance();
            if (client && client.connection) {
                await client.connection.emit('lock.unlock', door.slotLockId);
            }
        } else if (getLockStateFromACS(door.slotLockId) === LockState.unlocked) {
            const client = await SocketClient.getInstance();
            if (client && client.connection) {
                await client.connection.emit('lock.lock', door.slotLockId);
            }
        }
    };

    const toggleDoorLock = async (door: DoorData) => {
        if (locker?.isRemoteLock) {
            if (door.doorLockState === LockState.locked) {
                const results = await lockerService.unlockLock(door.doorLockId);
                if (results.success && results.data) {
                    UpdateLocker(results.data);
                }
            } else if (door.doorLockState === LockState.unlocked) {
                const results = await lockerService.lockLock(door.doorLockId);
                if (results.success && results.data) {
                    UpdateLocker(results.data);
                }
            }
        } else if (getLockStateFromACS(door.doorLockId) === LockState.locked) {
            const client = await SocketClient.getInstance();
            if (client && client.connection) {
                await client.connection.emit('lock.unlock', door.doorLockId);
            }
        } else if (getLockStateFromACS(door.doorLockId) === LockState.unlocked) {
            const client = await SocketClient.getInstance();
            if (client && client.connection) {
                await client.connection.emit('lock.lock', door.doorLockId);
            }
        }
    };

    const renderDoorInfo = () => locker?.doors.map((door) => (
        <Grid key={door.slotLockId} item lg={6} xs={12}>
            <Card>
                <CardContent>
                    <Typography variant="h4" style={{ marginBottom: '20px' }}>
                        {door?.name}
                        <Button
                          style={{ float: 'right' }}
                          color="primary"
                          variant="contained"
                          onClick={() => { setShowResetPackageConfirm(true); setResettingSlotId(door.slotLockId); }}
                        >
                            Reset Packages
                        </Button>
                    </Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="h6">
                                {`Current Packages: ${door?.packageCount}`}
                            </Typography>
                            <Typography variant="h6">
                                {`Last Service Date: ${door.lastServiceDate ? shortDateFormat(door.lastServiceDate) : 'Never'}`}
                            </Typography>
                            <Typography variant="h6">
                                {`Last Package Drop: ${door.lastPackageDrop ? shortDateFormat(door.lastPackageDrop) : 'Never'}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" className={`${classes.boldText} ${classes.centerText}`}>
                                Door Lock
                            </Typography>
                            <LockButton
                              classes={classes.lockButton}
                              lockState={locker.isRemoteLock ? door?.doorLockState : getLockStateFromACS(door?.doorLockId)}
                              onClick={() => toggleDoorLock(door)}
                            />
                            <Typography variant="h6" className={`${classes.boldText} ${classes.centerText}`}>
                                Slot Lock
                            </Typography>
                            <LockButton
                              classes={classes.lockButton}
                              lockState={locker.isRemoteLock ? door?.slotLockState : getLockStateFromACS(door?.slotLockId)}
                              onClick={() => toggleSlotLock(door)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    ))

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h3">
                    {`Locker # ${locker?.lockerId}`}
                    <Button
                      size="large"
                      style={{ float: 'right' }}
                      color="primary"
                      variant="contained"
                      onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </Typography>
                <Typography variant="h5">
                    {locker?.location}
                </Typography>
            </div>
            <Grid container spacing={3}>
                {renderDoorInfo()}
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Labels Remaining" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {locker?.labelsRemaining}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() => setShowRefillDialog(true)}
                            >
                                Refill
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Monthly Packages" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {locker?.monthlyPackages}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Daily Packages" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {locker?.dailyPackages}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Active Credentials" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {locker?.doors.map((door) => door.activeCredentials).reduce((prev, next) => prev + next)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ConfirmDialog
              open={showResetPackageConfirm}
              setOpen={setShowResetPackageConfirm}
              message="Are you sure you would like to reset the packages for this door?"
              title="Package Reset Confirmation"
              successCallback={() => resetPackage()}
            />
            <InputDialog
              open={showRefillDialog}
              setOpen={setShowRefillDialog}
              message="How many labels do you want to reset the counter to?"
              title="Refill Labels"
              successCallback={(data) => refillLabels(data)}
            />
        </>
    )
}

export default LockerDetail;
