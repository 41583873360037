import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, Grid, Input, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useAlertService from '../../Services/useAlertService';
import useDropDownService from '../../Services/useDropDownService';
import AlertData from '../../shared/types/AlertData';
import { DropDownOption } from '../../shared/types/DropDownResponse';

const AlertDialog = ({
    open,
    setOpen
}: AlertDialogProps) => {
    const dropDownService = useDropDownService();
    const alertService = useAlertService();

    const [newAlert, setNewAlert] = useState<AlertData>({
        lockerId: '',
        message: '',
        printerError: false
    });
    const [submitted, setSubmitted] = useState(false);

    const [lockerDropDown, setLockerDropDown] = useState<DropDownOption[] | null>(null);

    const resetAlert = () => {
        setNewAlert({
            lockerId: '',
            message: '',
            printerError: false
        });

        setSubmitted(false);
    }

    // Load the drop down options for the lockers
    useEffect(() => {
        async function loadLockerDropDown() {
            const data = await dropDownService.getDropDownData('lockers');
            if (data?.options) {
                setLockerDropDown(data?.options);
            }
        }

        loadLockerDropDown();
    }, []);

    useEffect(() => {
        if (open) {
            resetAlert();
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = async () => {
        setSubmitted(true);
        if (newAlert.message && newAlert.message.length > 0) {
            await alertService.createAlert(newAlert);
            setOpen(false);
        }
    };

    return (
        <Dialog
          open={open}
          keepMounted
          disableBackdropClick
        >
            <DialogTitle>New Alert</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    <Grid container style={{ maxWidth: '500px' }} spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Locker
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  fullWidth
                                  value={newAlert.lockerId}
                                  onChange={(e) => setNewAlert({ ...newAlert, lockerId: e.target.value as string })}
                                >
                                    {lockerDropDown && lockerDropDown.map((lock) => (
                                        <MenuItem key={lock.value} value={lock.value}>
                                            <ListItemText primary={lock.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAlert.message || newAlert.message.length === 0)}
                              label="Message"
                              InputLabelProps={{ shrink: true }}
                              value={newAlert.message}
                              onChange={(e) => setNewAlert({ ...newAlert, message: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Printer Error
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  fullWidth
                                  value={newAlert.printerError ? 'true' : 'false'}
                                  onChange={(e) => setNewAlert({ ...newAlert, printerError: e.target.value as string === 'true' })}
                                >
                                    <MenuItem value="true">
                                        <ListItemText primary="Yes" />
                                    </MenuItem>
                                    <MenuItem value="false">
                                        <ListItemText primary="No" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Close
                </Button>
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface AlertDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void
}

export default AlertDialog;
