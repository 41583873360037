import { Button,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Input,
    Checkbox,
    ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDriverService from '../../Services/useDriverService';
import useDropDownService from '../../Services/useDropDownService';
import DriverData from '../../shared/types/DriverData';
import { DropDownOption } from '../../shared/types/DropDownResponse';
import getDateDropDown from '../../Helpers/DropDown'
import { RootState } from '../../Store/mainStore';

const NewDriverDialog: React.FunctionComponent<NewDriverDialogProps> = (
    {
        open,
        setOpen,
        editUser
    }: NewDriverDialogProps
) => {
    const [newDriver, setNewDriver] = useState<DriverData>({
        id: '',
        name: '',
        lockerId: '',
        doorIds: [],
        doorName: '',
        requireLog: []
    });

    const driverService = useDriverService();
    const dropDownService = useDropDownService();

    const lockers = useSelector((root: RootState) => root.locker.lockers);

    const [submitted, setSubmitted] = useState<any>(false);
    const [lockerDropDown, setLockerDropDown] = useState<DropDownOption[] | null>(null);
    const [lockDropDown, setLockDropDown] = useState<DropDownOption[] | null>(null);

    // Load the drop down options for the locker and lock
    useEffect(() => {
        async function loadLockerDropDown() {
            const data = await dropDownService.getDropDownData('lockers');
            if (data?.options) {
                setLockerDropDown(data?.options);
            }
        }

        loadLockerDropDown();
    }, []);

    useEffect(() => {
        setLockDropDown(null);

        async function loadLockDropDown(dropDown: string) {
            const data = await dropDownService.getDropDownData(dropDown);
            if (data?.options) {
                setLockDropDown(data?.options);
            }

            // Clear out selected locks if they no longer exist
            if (newDriver.doorIds.length > 0) {
                const doorIds = newDriver.doorIds.filter(
                    (doorId: string) => data?.options?.find((option: DropDownOption) => option.value === doorId)
                );

                setNewDriver({
                    ...newDriver,
                    doorIds
                });
            }
        }

        if (newDriver.lockerId) {
            const selectedLocker = lockers.find((locker) => locker.lockerId === newDriver.lockerId);
            if (selectedLocker) {
                loadLockDropDown(selectedLocker.isRemoteLock ? 'rllocks' : 'locks');
            }
        }
    }, [newDriver.lockerId]);

    useEffect(() => {
        if (open) {
            setSubmitted(false);
            if (editUser) {
                setNewDriver(editUser);
            } else {
                setNewDriver({
                    id: '',
                    name: '',
                    lockerId: '',
                    pin: undefined,
                    doorIds: [],
                    doorName: '',
                    requireLog: [],
                    alertHour: undefined,
                    phoneNumber: undefined,
                    checkPrinter: undefined
                });
            }
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = async () => {
        setSubmitted(true);
        if (
            newDriver.name
            && newDriver.doorIds && newDriver.doorIds.length > 0
            && newDriver.doorName
            && (newDriver.pin || editUser)
            && newDriver.lockerId) {
            if (editUser) {
                await driverService.updateDriverData(newDriver);
            } else {
                await driverService.saveDriverData(newDriver);
            }
            setOpen(false);
        }
    };

    return (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          disableBackdropClick
        >
            <DialogTitle>{editUser ? 'Edit Driver' : 'New Driver'}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    <Grid container style={{ maxWidth: '500px' }} spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newDriver.name || newDriver.name.length === 0)}
                              label="Name"
                              InputLabelProps={{ shrink: true }}
                              value={newDriver.name}
                              onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required={!editUser}
                              fullWidth
                              type="number"
                              error={submitted && (!newDriver.pin && !editUser)}
                              label={editUser ? 'New Pin' : 'Pin'}
                              InputLabelProps={{ shrink: true }}
                              value={newDriver.pin ? newDriver.pin : ''}
                              onChange={(e) => setNewDriver({ ...newDriver, pin: parseInt(e.target.value, 10) })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Locker
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  fullWidth
                                  error={submitted && (!newDriver.lockerId || newDriver.lockerId.length === 0)}
                                  value={newDriver.lockerId}
                                  onChange={(e) => setNewDriver({ ...newDriver, lockerId: e.target.value as string })}
                                >
                                    {lockerDropDown && lockerDropDown.map((lock) => (
                                        <MenuItem key={lock.value} value={lock.value}>
                                            <ListItemText primary={lock.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    ACS Locks
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  multiple
                                  fullWidth
                                  disabled={!lockDropDown || lockDropDown.length === 0}
                                  error={submitted && (!newDriver.doorIds || newDriver.doorIds.length === 0)}
                                  value={newDriver.doorIds}
                                  renderValue={
                                      (selected) => (selected as string[]).map(
                                          (x) => lockDropDown?.find((y) => y.value === x)?.text
                                        ).join(', ')
                                    }
                                  onChange={(e) => setNewDriver({ ...newDriver, doorIds: e.target.value as string[] })}
                                >
                                    {lockDropDown && lockDropDown.map((lock) => (
                                        <MenuItem key={lock.value} value={lock.value}>
                                            <Checkbox checked={newDriver.doorIds.indexOf(lock.value) > -1} />
                                            <ListItemText primary={lock.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newDriver.doorName || newDriver.doorName.length === 0)}
                              label="Door Name (What the driver sees)"
                              InputLabelProps={{ shrink: true }}
                              value={newDriver.doorName}
                              onChange={(e) => setNewDriver({ ...newDriver, doorName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Require Log
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  multiple
                                  fullWidth
                                  value={newDriver.requireLog}
                                  renderValue={(selected) => (selected as string[]).join(', ')}
                                  onChange={(e) => setNewDriver({ ...newDriver, requireLog: e.target.value as string[] })}
                                >
                                    {['Amazon', 'UPS', 'FedEx', 'USPS'].map((log) => (
                                        <MenuItem key={log} value={log}>
                                            <Checkbox checked={newDriver.requireLog.indexOf(log) > -1} />
                                            <ListItemText primary={log} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Phone Number"
                              InputLabelProps={{ shrink: true }}
                              value={newDriver.phoneNumber ? newDriver.phoneNumber : ''}
                              onChange={(e) => setNewDriver({ ...newDriver, phoneNumber: e.target.value.replace(' ', '') })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Alert Time (EST)
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  fullWidth
                                  value={newDriver.alertHour ? newDriver.alertHour : null}
                                  onChange={(e) => setNewDriver({ ...newDriver, alertHour: e.target.value as number })}
                                >
                                    {getDateDropDown().map((time) => (
                                        <MenuItem key={time.value} value={time.value}>
                                            <ListItemText primary={time.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Printer Test
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  fullWidth
                                  value={newDriver.checkPrinter !== undefined ? newDriver.checkPrinter.toString() : null}
                                  onChange={(e) => setNewDriver({ ...newDriver, checkPrinter: (e.target.value as string) === 'true' })}
                                >
                                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                                    <MenuItem value="true">
                                        <ListItemText primary="Yes" />
                                    </MenuItem>
                                    <MenuItem value="false">
                                        <ListItemText primary="No" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Close
                </Button>
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface NewDriverDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void
    editUser?: DriverData
}

NewDriverDialog.defaultProps = {
    editUser: undefined
}

export default NewDriverDialog;
