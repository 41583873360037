import { useDispatch } from 'react-redux';
import SocketClient from '../Helpers/SocketClient';
import ApiResponse from '../shared/types/ApiResponse';
import { accessTokenActions } from '../Store/Reducers/accessTokenReducer';
import { AccessToken } from '../types/AccessToken';

interface acsAccessTokenServiceType {
    saveAccessToken: (accessToken: AccessToken) => Promise<string | undefined>,
    deleteAccessToken: (accessTokenId: string) => Promise<boolean>,
    getAccessTokens: () => Promise<boolean>,
}

const apiUrl = process.env.REACT_APP_ACS_URL;

const useACSAccessTokenService: () => acsAccessTokenServiceType = () => {
    const dispatch = useDispatch();

    const setToken = async (): Promise<string> => {
        const socketConnection = await SocketClient.getInstance();
        if (socketConnection && socketConnection.connection) {
            const auth = socketConnection.connection.auth as any;
            const token = auth.token as string;
            return token;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000))

        return setToken();
    };

    const getAccessTokens = async () => {
        const response = await fetch(`${apiUrl}/api/accesstoken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessToken[]>;
        dispatch({ type: accessTokenActions.LoadState, payload: results.data });

        if (results.success) {
            return true;
        }
        return false;
    };

    const saveAccessToken = async (accessToken: AccessToken) => {
        const response = await fetch(`${apiUrl}/api/accesstoken`, {
            method: 'POST',
            body: JSON.stringify(accessToken),
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessToken>;
        dispatch({ type: accessTokenActions.AddState, payload: results.data });

        if (results.success && results.data) {
            return results.data.token;
        }
        return '';
    };

    const deleteAccessToken = async (accessTokenId: string) => {
        const response = await fetch(`${apiUrl}/api/accesstoken/${accessTokenId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessToken>;
        if (results.success) {
            dispatch({ type: accessTokenActions.RemoveState, payload: results.data?._id });
            return true;
        }
        return false;
    };

    return { saveAccessToken, deleteAccessToken, getAccessTokens };
};

export default useACSAccessTokenService;
