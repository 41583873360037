import { useAuth0 } from '@auth0/auth0-react';
import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import ErrorIcon from '@material-ui/icons/Error';
import HistoryIcon from '@material-ui/icons/History';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SecurityIcon from '@material-ui/icons/Security';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(() => ({
    listItemText: {
        paddingTop: '5px'
    }
}));

const NavLinks: React.FunctionComponent<NavLinksProps> = ({ navigateCallback }: NavLinksProps) => {
    const classes = useStyles();

    const { logout } = useAuth0();
    const history = useHistory();

    const navigate = (url: string) => {
        history.push(url);
        if (navigateCallback) {
            navigateCallback(url);
        }
    }

    return (
        <List component="nav">
            <ListItem
              button
              onClick={() => navigate('/')}
              selected={history.location.pathname === '/'}
            >
                <ListItemIcon>
                    <HomeIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/locker')}
              selected={history.location.pathname.startsWith('/locker')}
            >
                <ListItemIcon>
                    <MarkunreadMailboxIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Lockers"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/user')}
              selected={history.location.pathname.startsWith('/user')}
            >
                <ListItemIcon>
                    <AccountCircleIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Users"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/return')}
              selected={history.location.pathname.startsWith('/return')}
            >
                <ListItemIcon>
                    <LocalShippingIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Returns"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/discount')}
              selected={history.location.pathname.startsWith('/discount')}
            >
                <ListItemIcon>
                    <MonetizationOnIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Discounts"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/driver')}
              selected={history.location.pathname.startsWith('/driver')}
            >
                <ListItemIcon>
                    <DriveEtaIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Drivers"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/feedback')}
              selected={history.location.pathname.startsWith('/feedback')}
            >
                <ListItemIcon>
                    <RssFeedIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Feedback"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/accessuser')}
              selected={history.location.pathname.startsWith('/accessuser')}
            >
                <ListItemIcon>
                    <AssignmentIndIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Access Users"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/accesslock')}
              selected={history.location.pathname.startsWith('/accesslock')}
            >
                <ListItemIcon>
                    <LockIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Access Locks"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/accesstoken')}
              selected={history.location.pathname.startsWith('/accesstoken')}
            >
                <ListItemIcon>
                    <SecurityIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Access Tokens"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/accesslog')}
              selected={history.location.pathname.startsWith('/accesslog')}
            >
                <ListItemIcon>
                    <HistoryIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Access Logs"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/alert')}
              selected={history.location.pathname.startsWith('/alert')}
            >
                <ListItemIcon>
                    <ErrorIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Alerts"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
            <ListItem
              button
              onClick={() => logout()}
            >
                <ListItemIcon>
                    <ExitToAppIcon color="action" />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  primaryTypographyProps={{ variant: 'h6' }}
                  className={classes.listItemText}
                />
            </ListItem>
        </List>
    );
};

NavLinks.defaultProps = {
    navigateCallback: undefined
}

interface NavLinksProps {
    navigateCallback?: (path: string) => void;
}

export default NavLinks;
