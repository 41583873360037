import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    }
}));

const Settings: React.FunctionComponent = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h3" className={classes.header}>
                Settings
            </Typography>
        </>
    );
};

export default Settings;
