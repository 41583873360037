import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import colors from '../../Styles/Colors';
import { RootState } from '../../Store/mainStore';
import GridExportBar from '../../Components/Grid/GridExportBar';
import AccessTokenDialog from '../../Components/Dialogs/AccessTokenDialog';
import useACSAccessTokenService from '../../Services/useACSAccessTokenService';
import { AccessLevel } from '../../types/AccessToken';
import ConfirmDialog from '../../Components/Dialogs/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const AccessTokenDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const accessTokens = useSelector((store: RootState) => store.accessToken);
    const [filteredTokens, setFilteredTokens] = useState(accessTokens);

    const acsAccessTokenService = useACSAccessTokenService();

    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteTokenId, setDeleteTokenId] = useState('');

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5
        },
        {
            field: 'level',
            headerName: 'Access Level',
            flex: 0.5
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: ({ getValue, id }: GridRenderCellParams) => {
                const tokenId = getValue(id, 'id') as string;
                return (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                            setDeleteTokenId(tokenId);
                            setShowDeleteDialog(true);
                        }}
                    >
                        Delete
                    </Button>
                );
            }
        }
    ];

    useEffect(() => {
        async function loadAccessTokenDetails() {
            await acsAccessTokenService.getAccessTokens();
            setLoading(false);
        }

        loadAccessTokenDetails();
    }, []);

    useEffect(() => {
        // Filter access tokens to only show level user
        const filteredAccessTokens = accessTokens.filter(
            (accessToken) => accessToken.level === AccessLevel.User
        );
        setFilteredTokens(filteredAccessTokens);
    }, [accessTokens]);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                {`Access Tokens | ${filteredTokens.length}  `}
                <Button
                  style={{ float: 'right' }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                            setShowDialog(true)
                      }}
                >
                    New Access Token
                </Button>
            </Typography>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => row.id}
                      classes={{
                          columnHeader: classes.gridHeader
                      }}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      rows={filteredTokens}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={loading}
                      pageSize={10}
                    />
                </Grid>
            </Grid>
            <AccessTokenDialog
              open={showDialog}
              setOpen={setShowDialog}
            />
            <ConfirmDialog
              open={showDeleteDialog}
              setOpen={setShowDeleteDialog}
              title="Delete Access Token"
              message="Are you sure you want to delete this access token?"
              successCallback={async () => {
                    await acsAccessTokenService.deleteAccessToken(deleteTokenId);
                    setShowDeleteDialog(false);
                }}
            />
        </>
    );
};

export default AccessTokenDash;
