import { CircularProgress, makeStyles, Typography, Button, Grid } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ConfirmDialog from '../../Components/Dialogs/ConfirmDialog';
import NewDriverDialog from '../../Components/Dialogs/NewDriverDialog';
import GridExportBar from '../../Components/Grid/GridExportBar';
import useDriverService from '../../Services/useDriverService';
import useLockerService from '../../Services/useLockerService';
import DriverData from '../../shared/types/DriverData';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const DriverDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const drivers = useSelector((store: RootState) => store.driver);
    const driverService = useDriverService();
    const lockerService = useLockerService();

    const [selectedDriver, setSelectedDriver] = useState<DriverData | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [newDriverDialogOpen, setNewDriverDialogOpen] = useState(false);
    const [showDeleteDriverDialog, setShowDeleteDriverDialog] = useState(false);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5
        },
        {
            field: 'lockerId',
            headerName: 'Locker',
            flex: 0.5,
            renderCell: ({ id, getValue }: GridRenderCellParams) => {
                const lockerId = getValue(id, 'lockerId') as string[]
                return `${lockerId}`
            },
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const lockerId = getValue(id, 'lockerId') as string[]
                return `# ${lockerId}`
            }
        },
        {
            field: 'doorName',
            headerName: 'Door Name',
            flex: 0.5
        },
        {
            field: 'requireLog',
            headerName: 'Log Required',
            flex: 0.3,
            renderCell: ({ id, getValue }: GridCellParams) => {
                const logRequired = getValue(id, 'requireLog') as string[]
                if (logRequired && logRequired.length > 0) {
                    return '✔️';
                }
                return '❌';
            },
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const logRequired = getValue(id, 'requireLog') as string[]
                if (logRequired && logRequired.length > 0) {
                    return 'Yes';
                }
                return 'No';
            }
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 0.75,
            renderCell: ({ id, getValue }: GridCellParams) => {
                const currentDriverId = getValue(id, 'id') as string;
                const currentDriver = drivers.find((driver) => driver.id === currentDriverId);

                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            setSelectedDriver(currentDriver);
                            e.stopPropagation();
                            setNewDriverDialogOpen(true);
                          }}
                        >
                            EDIT
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedDriver(currentDriver);
                            setShowDeleteDriverDialog(true);
                          }}
                        >
                            Delete
                        </Button>
                    </div>
                )
            },
            valueFormatter: () => ''
        }
    ];

    useEffect(() => {
        setIsLoading(true);
        async function loadData() {
            try {
                await driverService.getDriverData();
                await lockerService.getLockers();
            } finally {
                setIsLoading(false);
            }
        }
        loadData();
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h4" color="inherit">
                    Drivers
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                            setSelectedDriver(undefined);
                            setNewDriverDialogOpen(true)
                      }}
                    >
                        New Driver
                    </Button>
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                      classes={{ root: classes.gridHeader }}
                      columns={columns}
                      checkboxSelection={false}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      autoHeight
                      loading={isLoading}
                      pageSize={10}
                      rows={drivers}
                      onRowClick={(selectedRow) => {
                            history.push(`/driver/${selectedRow.getValue(selectedRow.id, 'id')}`)
                        }}
                    />
                </Grid>
            </Grid>
            <NewDriverDialog open={newDriverDialogOpen} setOpen={setNewDriverDialogOpen} editUser={selectedDriver} />
            <ConfirmDialog
              message="Are you sure you want to delete this driver?"
              title="Delete Driver"
              open={showDeleteDriverDialog}
              setOpen={setShowDeleteDriverDialog}
              successCallback={() => {
                  if (selectedDriver) {
                    driverService.deleteDriver(selectedDriver.id);
                  }
              }}
            />
        </>
    )
}

export default DriverDash;
