/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import { AccessLock } from '../../types/AccessLock';

const initialState: AccessLock[] = [];

export enum accessLockActions {
    LoadState = 'AccessLockActions_LoadState',
    UnlockLock = 'AccessLockActions_UnlockLock',
    LockLock = 'AccessLockActions_LockLock',
    RemoveState = 'AccessLockActions_RemoveState'
}

export default function accessLockReducer(state = initialState, action: AnyAction) {
    if (action.type === accessLockActions.LoadState) {
        const accessLocks: AccessLock[] = action.payload;
        return accessLocks;
    }

    if (action.type === accessLockActions.UnlockLock) {
        const accessLockId: string = action.payload;
        // Set the access lock to unlocked
        const newState = state.map((accessLock: AccessLock) => {
            if (accessLock._id === accessLockId) {
                return {
                    ...accessLock,
                    locked: false
                };
            }
            return accessLock;
        });

        return newState;
    }

    if (action.type === accessLockActions.LockLock) {
        const accessLockId: string = action.payload;
        // Set the access lock to unlocked
        const newState = state.map((accessLock: AccessLock) => {
            if (accessLock._id === accessLockId) {
                return {
                    ...accessLock,
                    locked: true
                };
            }
            return accessLock;
        });

        return newState;
    }

    if (action.type === accessLockActions.RemoveState) {
        const accessLockId: string = action.payload;
        // Remove access lock from state
        const newState = state.filter((accessLock: AccessLock) => accessLock._id !== accessLockId);
        return newState;
    }

    return state;
}
