import { Card, CardHeader, CardContent, Typography, makeStyles, Grid, IconButton } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LockButton from '../Buttons/LockButton';
import useLockerService from '../../Services/useLockerService';
import DoorData from '../../shared/types/DoorData';
import { RLLockData } from '../../shared/types/RLLocksResponse';
import LockerData from '../../shared/types/LockerData';
import LockState from '../../shared/types/LockState';
import shortDateFormat from '../../Helpers/DateFormatter';
import colors from '../../Styles/Colors';
import { RootState } from '../../Store/mainStore';
import SocketClient from '../../Helpers/SocketClient';

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        height: 'auto'
    },
    lockButton: {
        width: '100%'
    },
    sideHeaders: {
        fontWeight: 'bold'
    },
    content: {
        textAlign: 'center'
    },
    serviced: {
        border: `2px solid ${colors.lightGray}`,
        paddingTop: '5px',
        paddingBottom: '5px'
    }
}));

const LockerCard: React.FunctionComponent<LockerCardProps> = ({ Locker, UpdateLocker } : LockerCardProps) => {
    const classes = useStyles();
    const lockerService = useLockerService();
    const history = useHistory();

    const acsLockData = useSelector((root: RootState) => root.accessLock);

    const getLockStateFromACS = (lockId: string) => {
        if (acsLockData) {
            const acsLock = acsLockData.find((lock) => lock._id === lockId);
            if (acsLock && acsLock.isOnline) {
                return acsLock.locked ? LockState.locked : LockState.unlocked;
            }
        }
        return LockState.offline;
    };

    const toggleLock = async (door: DoorData) => {
        if (Locker.isRemoteLock) {
            if (door.slotLockState === LockState.locked) {
                const results = await lockerService.unlockLock(door.slotLockId);
                if (results.success && results.data) {
                    UpdateLocker(results.data);
                }
            } else if (door.slotLockState === LockState.unlocked) {
                const results = await lockerService.lockLock(door.slotLockId);
                if (results.success && results.data) {
                    UpdateLocker(results.data);
                }
            }
        } else if (getLockStateFromACS(door.slotLockId) === LockState.locked) {
            const client = await SocketClient.getInstance();
            if (client && client.connection) {
                await client.connection.emit('lock.unlock', door.slotLockId);
            }
        } else if (getLockStateFromACS(door.slotLockId) === LockState.unlocked) {
            const client = await SocketClient.getInstance();
            if (client && client.connection) {
                await client.connection.emit('lock.lock', door.slotLockId);
            }
        }
    };

    const navigateToDetails = () => {
        history.push(`/locker/${Locker.lockerId}`);
    }

    return (
        <Card>
            <CardHeader
              title={`Locker #${Locker.lockerId}`}
              subheader={Locker.location}
              subheaderTypographyProps={{ color: 'textPrimary' }}
              action={(
                  <IconButton onClick={navigateToDetails} color="primary">
                      <EditIcon />
                  </IconButton>
              )}
            />
            <CardContent>
                <Grid container className={classes.content} spacing={2}>
                    {
                        Locker.doors.map((door) => (
                            <Grid key={door.slotLockId} item xs={6}>
                                <Typography variant="h5" className={classes.sideHeaders}>
                                    {door.name}
                                </Typography>
                                <LockButton
                                  lockState={Locker.isRemoteLock ? door.slotLockState : getLockStateFromACS(door.slotLockId)}
                                  classes={classes.lockButton}
                                  onClick={() => toggleLock(door)}
                                />
                                <Typography variant="h6">
                                    {`${door.packageCount} Packages`}
                                </Typography>
                                <Typography variant="body1" className={classes.serviced}>
                                    {`Serviced: ${door.lastServiceDate ? shortDateFormat(door.lastServiceDate, false) : 'N/A'}`}
                                </Typography>
                            </Grid>
                        ))
                    }
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {`${Locker.labelsRemaining} Labels Remaining`}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

interface LockerCardProps {
    Locker: LockerData,
    UpdateLocker: (updateInfo: RLLockData) => void
}

export default LockerCard;
