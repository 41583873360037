import { Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useACSAccessTokenService from '../../Services/useACSAccessTokenService';
import { AccessLevel, AccessToken } from '../../types/AccessToken';
import ConfirmDialog from './ConfirmDialog';

const AccessTokenDialog: React.FunctionComponent<AccessTokenDialogProps> = (
    {
        open,
        setOpen
    }: AccessTokenDialogProps
) => {
    const [newAccessToken, setNewAccessToken] = useState<AccessToken>({
        _id: '',
        level: AccessLevel.User,
        name: ''
    });

    const accessTokenService = useACSAccessTokenService();
    const [submitted, setSubmitted] = useState<any>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [newAccessTokenDialog, setNewAccessTokenDialog] = useState<boolean>(false);
    const [newSecret, setNewSecret] = useState<string>('');

    useEffect(() => {
        if (open) {
            setSubmitted(false);
            setNewAccessToken({
                _id: '',
                level: AccessLevel.User,
                name: ''
            });
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = async () => {
        setSubmitted(true);
        setLoading(true);

        if (
            newAccessToken.name
            && newAccessToken.level
        ) {
            const results = await accessTokenService.saveAccessToken(newAccessToken);
            if (results) {
                setNewSecret(btoa(`${newAccessToken.name}:${results}`))
                setOpen(false);
                setNewAccessTokenDialog(true);
            }

            setLoading(false);
        }
    };

    return (
        <>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              disableBackdropClick
            >
                <DialogTitle>New Access Token</DialogTitle>
                <DialogContent>
                    <DialogContentText color="textPrimary">
                        <Grid container style={{ maxWidth: '500px' }} spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                  required
                                  fullWidth
                                  error={submitted && (!newAccessToken.name || newAccessToken.name.length === 0)}
                                  label="Name"
                                  InputLabelProps={{ shrink: true }}
                                  value={newAccessToken.name}
                                  onChange={(e) => setNewAccessToken({ ...newAccessToken, name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading} color="secondary" variant="contained">
                        Close
                    </Button>
                    <Button onClick={handleCloseSuccess} disabled={loading} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
              title="New Token Created"
              open={newAccessTokenDialog}
              setOpen={setNewAccessTokenDialog}
              message={`This is the token secret, you will not be able to access this once this screen has been closed: ${newSecret}`}
              showNo={false}
              successText="Okay"
              successCallback={() => setNewSecret('')}
            />
        </>
    )
}

interface AccessTokenDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void
}

export default AccessTokenDialog;
