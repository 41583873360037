import { combineReducers, createStore } from 'redux'
import accessHistoryReducer from './Reducers/accessHistoryReducer';
import accessLockReducer from './Reducers/accessLockReducer';
import accessTokenReducer from './Reducers/accessTokenReducer';
import accessUserReducer from './Reducers/accessUserReducer';
import alertReducer from './Reducers/alertReducer';
import dashboardReducer from './Reducers/dashboardReducer';
import discountReducer from './Reducers/discountReducer';
import driverReducer from './Reducers/driverReducer';
import feedbackReducer from './Reducers/feedbackReducer';
import lockerReducer from './Reducers/lockerReducer';
import returnReducer from './Reducers/returnReducer';
import userReducer from './Reducers/userReducer';

const rootReducers = combineReducers({
    locker: lockerReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    return: returnReducer,
    discount: discountReducer,
    driver: driverReducer,
    feedback: feedbackReducer,
    accessUser: accessUserReducer,
    accessLock: accessLockReducer,
    accessToken: accessTokenReducer,
    accessHistory: accessHistoryReducer,
    alert: alertReducer
})

const mainStore = createStore(
    rootReducers
);

export type RootState = ReturnType<typeof rootReducers>

export default mainStore;
