import React, { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { DataGrid, GridCellParams, GridColDef, GridSortItem } from '@mui/x-data-grid';
import colors from '../../Styles/Colors';
import { RootState } from '../../Store/mainStore';
import ReturnDetail from '../../shared/types/ReturnDetail';
import shortDateFormat from '../../Helpers/DateFormatter';
import GridExportBar from '../../Components/Grid/GridExportBar';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const columns: GridColDef[] = [
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5
    },
    {
        field: 'timestamp',
        headerName: 'Timestamp',
        flex: 0.5,
        renderCell: ({ id, getValue }: GridCellParams) => {
            const timestamp = getValue(id, 'timestamp') as string
            return shortDateFormat(new Date(timestamp));
        }
    },
    {
        field: 'message',
        headerName: 'Details',
        flex: 0.5
    }
];

const ReturnDetails: React.FunctionComponent = () => {
    const { returnId } = useParams<{returnId: string}>();

    const classes = useStyles();
    const history = useHistory();

    const returns = useSelector((store: RootState) => store.return.returns);

    const [returnDetail, setReturnDetail] = useState<ReturnDetail | undefined>(undefined);
    const [tableSort, setTableSort] = useState<GridSortItem[]>([{ field: 'timestamp', sort: 'desc' }]);

    useEffect(() => {
        const currentReturn = returns.find((r) => r._id === returnId);
        setReturnDetail(currentReturn);
        if (!currentReturn) {
            history.push('/return');
        }
    }, [returns]);

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h3">
                    Return Details
                    <Button
                      size="large"
                      style={{ float: 'right' }}
                      color="primary"
                      variant="contained"
                      onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </Typography>
                <Typography variant="h5">
                    {returnDetail?.carrier}
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item lg={3} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Return Value" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {returnDetail?.returnValue}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Category" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {returnDetail?.category}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Vendor" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {returnDetail?.vendor}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                    <Card
                      className={classes.statCard}
                      style={{ cursor: returnDetail?.returnLabelLink ? 'pointer' : 'default' }}
                      onClick={() => returnDetail?.returnLabelLink && window.open(returnDetail?.returnLabelLink, '_blank')}
                    >
                        <CardHeader title="Label Uploaded" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {returnDetail?.returnLabel && returnDetail?.returnLabel !== 'none' ? '✔️' : '❌'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Tracking" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {returnDetail?.trackingNumber}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                              size="large"
                              color="primary"
                              component="a"
                              fullWidth
                              variant="contained"
                              href={returnDetail?.trackingLink}
                              target="_blank"
                            >
                                Track Package
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="User" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {returnDetail?.ownedByEmail}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                              size="large"
                              color="primary"
                              component="a"
                              fullWidth
                              variant="contained"
                              onClick={() => history.push(`/user/${returnDetail?.ownedBy}`)}
                            >
                                View User
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                      classes={{ columnHeader: classes.gridHeader }}
                      getRowId={(row) => `${row._id}${row.timestamp}`}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      components={{
                          Toolbar: GridExportBar
                      }}
                      sortModel={tableSort}
                      onSortModelChange={(sortModel) => setTableSort(sortModel)}
                      pageSize={5}
                      rows={returnDetail?.history ? returnDetail.history : []}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ReturnDetails;
