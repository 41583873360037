import { useDispatch } from 'react-redux';
import SocketClient from '../Helpers/SocketClient';
import ApiResponse from '../shared/types/ApiResponse';
import { accessLockActions } from '../Store/Reducers/accessLockReducer';
import { AccessLock } from '../types/AccessLock';

interface acsAccessLockServiceType {
    saveAccessLock: (accessLock: AccessLock) => Promise<boolean>,
    updateAccessLock: (accessLock: AccessLock) => Promise<boolean>,
    deleteAccessLock: (accessLockId: string) => Promise<boolean>,
}

const apiUrl = process.env.REACT_APP_ACS_URL;

const useACSAccessLockService: () => acsAccessLockServiceType = () => {
    const dispatch = useDispatch();

    const setToken = async (): Promise<string> => {
        const socketConnection = await SocketClient.getInstance();
        if (socketConnection && socketConnection.connection) {
            const auth = socketConnection.connection.auth as any;
            const token = auth.token as string;
            return token;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000))

        return setToken();
    };

    const saveAccessLock = async (accessLock: AccessLock) => {
        const response = await fetch(`${apiUrl}/api/accesslock`, {
            method: 'POST',
            body: JSON.stringify(accessLock),
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessLock>;

        if (results.success) {
            return true;
        }
        return false;
    };

    const updateAccessLock = async (accessLock: AccessLock) => {
        const response = await fetch(`${apiUrl}/api/accesslock/${accessLock._id}`, {
            method: 'PATCH',
            body: JSON.stringify(accessLock),
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessLock>;

        if (results.success) {
            return true;
        }
        return false;
    };

    const deleteAccessLock = async (accessLockId: string) => {
        const response = await fetch(`${apiUrl}/api/accesslock/${accessLockId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessLock>;

        if (results.success) {
            dispatch({ type: accessLockActions.RemoveState, payload: results.data?._id });
            return true;
        }
        return false;
    };

    return { saveAccessLock, updateAccessLock, deleteAccessLock };
};

export default useACSAccessLockService;
