/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import { AccessToken } from '../../types/AccessToken';

const initialState: AccessToken[] = [];

export enum accessTokenActions {
    LoadState = 'AccessTokenActions_LoadState',
    AddState = 'AccessTokenActions_AddState',
    RemoveState = 'AccessTokenActions_RemoveState'
}

export default function accessTokenReducer(state = initialState, action: AnyAction) {
    if (action.type === accessTokenActions.LoadState) {
        const accessTokens: AccessToken[] = action.payload;
        if (accessTokens) {
            return accessTokens;
        }
        return [];
    }

    if (action.type === accessTokenActions.AddState) {
        const accessToken: AccessToken = action.payload;
        return [...state, accessToken];
    }

    if (action.type === accessTokenActions.RemoveState) {
        const tokenId: string = action.payload;
        const filteredState = state.filter((token: any) => token.id !== tokenId);
        return filteredState;
    }

    return state;
}
