import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const initialChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
                precision: 0
            }
        }
    }
}

const SubscriptionBreakdownChart: React.FunctionComponent = () => {
    const subscriptions = useSelector((store: RootState) => store.dashboard.subscriptions);

    const initialChartData = {
        labels: ['Free Trial', 'Cart Dealer', 'Scoopreme'],
        datasets: [
            {
                label: 'Users',
                data: [0],
                fill: false,
                backgroundColor: colors.mainBlue,
                borderColor: colors.mainBlue
            }
        ]
    }

    const [chartData, setChartData] = useState(initialChartData);

    useEffect(() => {
        if (chartData) {
            const newData = chartData;

            const today = new Date();
            today.setDate(today.getDate() - 30);

            if (newData.datasets?.[0]) {
                newData.datasets[0].data = [0, 0, 0];
                newData.datasets[0].data[0] = subscriptions.freeTrial;
                newData.datasets[0].data[1] = subscriptions.cartDealer;
                newData.datasets[0].data[2] = subscriptions.scoopreme;
            }

            setChartData(newData);
        }
    }, [chartData, subscriptions])

    return <Bar data={chartData} options={initialChartOptions} />
};

export default SubscriptionBreakdownChart;
