import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColDef, GridSortItem, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import colors from '../../Styles/Colors';
import { RootState } from '../../Store/mainStore';
import GridExportBar from '../../Components/Grid/GridExportBar';
import useACSAccessHistoryService from '../../Services/useACSAccessHistoryService';
import shortDateFormat from '../../Helpers/DateFormatter';
import { AccessLevel } from '../../types/AccessToken';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    },
    unlockCell: {
        backgroundColor: 'green',
        color: 'white'
    },
    lockCell: {
        backgroundColor: 'red',
        color: 'white'
    }
}));

const AccessLogDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const accessLogs = useSelector((store: RootState) => store.accessHistory);
    const accessUsers = useSelector((store: RootState) => store.accessUser);
    const accessLocks = useSelector((store: RootState) => store.accessLock);
    const accessTokens = useSelector((store: RootState) => store.accessToken);

    const acsAccessHistoryService = useACSAccessHistoryService();

    const [tableSort, setTableSort] = useState<GridSortItem[]>([{ field: 'timestamp', sort: 'desc' }]);
    const [loading, setLoading] = useState(true);

    const columns: GridColDef[] = [
        {
            field: 'origin',
            headerName: 'Item',
            flex: 0.5,
            valueGetter: ({ id, getValue }: GridValueGetterParams) => {
                const lockId = getValue(id, 'lockId');
                const accessUserId = getValue(id, 'accessUserId');

                if (lockId) {
                    const accessLock = accessLocks.find((lock) => lock._id === lockId);
                    if (accessLock) {
                        return `${accessLock.name} - ${accessLock.lockerId}`;
                    }
                    return `Deleted Lock ${lockId}`;
                }

                if (accessUserId) {
                    const accessUser = accessUsers.find((user) => user._id === accessUserId);
                    if (accessUser) {
                        return `${accessUser.name} - ${accessUser.accessCode}`;
                    }
                    return `Deleted User ${accessUserId}`;
                }

                return 'Unknown';
            }
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            flex: 0.5,
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const data = getValue(id, 'timestamp') as string;
                if (data) {
                    return shortDateFormat(new Date(data));
                }
                return '';
            }
        },
        {
            field: 'source',
            headerName: 'Source',
            flex: 0.5,
            valueFormatter: ({ id, getValue }: GridValueFormatterParams) => {
                const source = getValue(id, 'source') as string;

                if (source) {
                    const sourceToken = accessTokens.find((token) => token.name === source);
                    if (sourceToken) {
                        if (sourceToken.level === AccessLevel.Admin) {
                            return 'Admin';
                        }
                    }
                }

                return source;
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.5
        },
        {
            field: 'method',
            headerName: 'Method',
            flex: 0.5
        }
    ];

    useEffect(() => {
        async function loadAccessTokenDetails() {
            await acsAccessHistoryService.getAccessHistory();
            setLoading(false);
        }

        loadAccessTokenDetails();
    }, []);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                {`Access Logs | ${accessLogs.length}  `}
            </Typography>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => row._id}
                      classes={{
                          columnHeader: classes.gridHeader
                      }}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      sortModel={tableSort}
                      onSortModelChange={(sortModel) => setTableSort(sortModel)}
                      rows={accessLogs}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={loading}
                      pageSize={10}
                      getCellClassName={(params: GridCellParams) => {
                        if (params.field.toLocaleLowerCase() === 'method') {
                            if (params.value === 'unlocked' || params.value === 'Unlocked') {
                                return classes.unlockCell;
                            }

                            if (params.value === 'locked' || params.value === 'Locked') {
                                return classes.lockCell;
                            }
                        }

                        return '';
                      }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AccessLogDash;
