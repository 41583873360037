import { useDispatch } from 'react-redux';
import SocketClient from '../Helpers/SocketClient';
import ApiResponse from '../shared/types/ApiResponse';
import { accessHistoryActions } from '../Store/Reducers/accessHistoryReducer';
import { AccessHistory } from '../types/AccessHistory';

interface acsAccessHistoryServiceType {
    getAccessHistory: () => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_ACS_URL;

const useACSAccessHistoryService: () => acsAccessHistoryServiceType = () => {
    const dispatch = useDispatch();

    const setToken = async (): Promise<string> => {
        const socketConnection = await SocketClient.getInstance();
        if (socketConnection && socketConnection.connection) {
            const auth = socketConnection.connection.auth as any;
            const token = auth.token as string;
            return token;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000))

        return setToken();
    };

    const getAccessHistory = async () => {
        const response = await fetch(`${apiUrl}/api/accesshistory`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessHistory>;

        if (results.success) {
            dispatch({ type: accessHistoryActions.LoadState, payload: results.data });
            return true;
        }
        return false;
    };

    return { getAccessHistory };
};

export default useACSAccessHistoryService;
