function pad(data: number) {
    return (data < 10) ? `0${data.toString()}` : data.toString();
}

function shortDateFormat(date: Date, year: boolean = true) {
    const parsedDate = new Date(date);

    let hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();

    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour 0 should be 12
    const minuteOutput = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minuteOutput} ${suffix}`;

    let dateString = `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
    if (year) {
        dateString += `/${parsedDate.getFullYear()}`;
    }
    return `${dateString} ${strTime}`;
}

export function muiDateFormat(date: Date) {
    return (
        `${date.getFullYear()
        }-${pad(date.getMonth() + 1)
        }-${pad(date.getDate())
        }T${pad(date.getHours())
        }:${pad(date.getMinutes())
        }:${pad(date.getSeconds())}`
    );
}

export default shortDateFormat;
