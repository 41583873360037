import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Navigation from '../Components/Dashboard/Navigation';
import LockerDash from './Lockers/LockerDash';
import Dashboard from './Dashboard/Dashboard';
import LockerDetail from './Lockers/LockerDetail';
import Settings from './Settings/Settings';
import UserDash from './Users/UserDash';
import UserDetails from './Users/UserDetails';
import ReturnDash from './Returns/ReturnDash';
import ReturnDetails from './Returns/ReturnDetails';
import DiscountDash from './Discounts/DiscountDash';
import DriverDash from './Driver/DriverDash';
import DriverDetails from './Driver/DriverDetails';
import DriverLogDetails from './Driver/DriverLogDetails';
import FeedbackDash from './Feedback/FeedbackDash';
import AccessUserDash from './AccessUsers/AccessUserDash';
import AccessLockDash from './AccessLocks/AccessLockDash';
import AccessTokenDash from './AccessToken/AccessTokenDash';
import AccessLogDash from './AccessLogs/AccessLogDash';
import AlertDash from './Alert/AlertDash';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
        flex: 1,
        [theme.breakpoints.down(1000)]: {
            marginBottom: '50px',
            width: '100%'
        }
    }
}));

const Routes: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Navigation />
            <div className={classes.content}>
                <Switch>
                    <Route path={['/']} exact component={Dashboard} />
                    <Route path={['/locker']} exact component={LockerDash} />
                    <Route path={['/locker/:lockerId']} exact component={LockerDetail} />
                    <Route path={['/user']} exact component={UserDash} />
                    <Route path={['/user/:userId']} exact component={UserDetails} />
                    <Route path={['/return']} exact component={ReturnDash} />
                    <Route path={['/discount']} exact component={DiscountDash} />
                    <Route path={['/return/:returnId']} exact component={ReturnDetails} />
                    <Route path={['/settings']} exact component={Settings} />
                    <Route path={['/feedback']} exact component={FeedbackDash} />
                    <Route path={['/driver']} exact component={DriverDash} />
                    <Route path={['/driver/:driverId']} exact component={DriverDetails} />
                    <Route path={['/driver/:driverId/:logId']} exact component={DriverLogDetails} />
                    <Route path={['/accessuser']} exact component={AccessUserDash} />
                    <Route path={['/accesslock']} exact component={AccessLockDash} />
                    <Route path={['/accesstoken']} exact component={AccessTokenDash} />
                    <Route path={['/accesslog']} exact component={AccessLogDash} />
                    <Route path={['/alert']} exact component={AlertDash} />
                </Switch>
            </div>
        </>
    );
};

export default withRouter(Routes);
