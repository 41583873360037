import { AnyAction } from 'redux'
import DriverData, { DriverLogData } from '../../shared/types/DriverData';

const initialState: DriverData[] = [];

export enum driverActions {
    LoadDrivers = 'DriverActions_LoadDrivers',
    LoadDriverLogs = 'DriverActions_LoadDriverLogs',
    SaveDriverData = 'DriverActions_SaveDriverData',
    DeleteDriverData = 'DriverActions_DeleteDriverData',
    UpdateDriverData = 'DriverActions_UpdateDriverData',
}

export default function dashboardReducer(state = initialState, action: AnyAction): DriverData[] {
    if (action.type === driverActions.LoadDrivers) {
        const dashboardData: DriverData[] = action.payload;
        return dashboardData
    }

    if (action.type === driverActions.LoadDriverLogs) {
        const driverLogs: DriverLogData[] = action.payload.data;
        // Update the driver logs in the state
        return state.map((driver) => {
            if (driver.id === action.payload.driverId) {
                return {
                    ...driver,
                    driverLogs
                }
            }
            return driver;
        })
    }

    if (action.type === driverActions.SaveDriverData) {
        const driverData: DriverData = action.payload;
        // add the driver data to the state
        return [...state, driverData];
    }

    if (action.type === driverActions.DeleteDriverData) {
        const driverId: string = action.payload.id;
        // remove the driver data from the state
        return state.filter((driver) => driver.id !== driverId);
    }

    if (action.type === driverActions.UpdateDriverData) {
        const driverData: DriverData = action.payload;
        // update the driver data in the state
        return state.map((driver) => {
            if (driver.id === driverData.id) {
                return driverData;
            }
            return driver;
        })
    }

    return state;
}
