import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ConfirmDialog from '../../Components/Dialogs/ConfirmDialog';
import InputDialog from '../../Components/Dialogs/InputDialog';
import GridExportBar from '../../Components/Grid/GridExportBar';
import useUserService from '../../Services/useUserService';
import { ReturnHistory } from '../../shared/types/ReturnDetail';
import SubscriptionType from '../../shared/types/SubscriptionTypes';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const columns: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Label',
        flex: 0.3,
        renderCell: ({ id, getValue }: GridRenderCellParams) => {
            const returnLabelId = getValue(id, 'returnLabel') as string
            if (returnLabelId && returnLabelId !== 'none') {
                return '✔️';
            }
            return '❌';
        },
        valueFormatter: ({ getValue, id }: GridValueFormatterParams) => {
            const returnLabelId = getValue(id, 'returnLabel') as string
            if (returnLabelId && returnLabelId !== 'none') {
                return 'Yes';
            }
            return 'No';
        }
    },
    {
        field: 'returnTitle',
        headerName: 'Title',
        flex: 0.5
    },
    {
        field: 'returnValue',
        headerName: 'Value',
        flex: 0.5
    },
    {
        field: 'category',
        headerName: 'Category',
        flex: 0.5
    },
    {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 0.5
    },
    {
        field: 'carrier',
        headerName: 'Carrier',
        flex: 0.5
    },
    {
        field: 'Status',
        headerName: 'Status',
        flex: 0.5,
        valueGetter: ({ id, getValue }: GridValueGetterParams) => {
            let history = getValue(id, 'history') as ReturnHistory[];
            history = history?.filter((h) => h.status !== 'Other');
            history.sort((a, b) => new Date(b.timestamp)?.getTime() - new Date(a.timestamp)?.getTime());
            return history[0].status;
        }
    },
    {
        field: 'trackingNumber',
        headerName: 'Track',
        flex: 0.5,
        renderCell: ({ id, getValue }: GridCellParams) => {
            const trackingLink = getValue(id, 'trackingLink') as string;
            const trackingNumber = getValue(id, 'trackingNumber') as string;
            return (
                <a target="_blank" href={trackingLink} rel="noreferrer">
                    {trackingNumber}
                </a>
            )
        },
        valueFormatter: ({ getValue, id }: GridValueFormatterParams) => {
            const trackingLink = getValue(id, 'trackingLink') as string;
            return trackingLink;
        }
    }
];

const UserDetails: React.FunctionComponent = () => {
    const { userId } = useParams<{userId: string}>();
    const classes = useStyles();

    const history = useHistory();

    const [showConfirmUpgrade, setShowConfirmUpgrade] = useState(false);

    const userDetails = useSelector((store: RootState) => store.user.currentUserDetails);
    const userService = useUserService();

    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionChangeLoading, setSubscriptionChangeLoading] = useState(false);
    const [refillDialogOpen, setRefillDialogOpen] = useState(false);

    useEffect(() => {
        async function loadUserDetails() {
            setIsLoading(true);
            const results = await userService.getUserDetailsData(userId);

            if (results) {
                setIsLoading(false);
            } else {
                history.push('/user');
            }
        }

        loadUserDetails();
    }, [])

    const updateReturnCredits = async (data: string) => {
        await userService.updateUserReturnCredits(userId, parseInt(data, 10));
        setRefillDialogOpen(false);
    }

    const upgradeToFreeTrial = async () => {
        setSubscriptionChangeLoading(true);
        await userService.upgradeUserToFreeTrial(userId);
        setSubscriptionChangeLoading(false);
    }

    const getSubscriptionText = () => {
        if (userDetails.subscriptionTier === SubscriptionType.CartDealer) {
            return <span>Cart Dealer</span>;
        }

        if (userDetails.subscriptionTier === SubscriptionType.FreeTrial) {
            return <span>Free Trial</span>;
        }

        return <span>Scoopreme</span>;
    }

    if (isLoading) {
        return (<CircularProgress />);
    }

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h3">
                    <Grid container justify="space-between">
                        <Grid item>
                            {`${userDetails.firstName} ${userDetails.lastName}`}
                        </Grid>
                        <Grid item style={{ display: 'flex', gap: '10px', flexDirection: 'row-reverse' }}>
                            <Button
                              style={{ height: '42px' }}
                              size="large"
                              color="primary"
                              variant="contained"
                              onClick={() => history.goBack()}
                            >
                                Back
                            </Button>
                            {
                                userDetails.canUpgradeToFreeTrial
                                && (
                                <Button
                                  style={{ height: '42px' }}
                                  size="large"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => setShowConfirmUpgrade(true)}
                                >
                                    {subscriptionChangeLoading ? <CircularProgress size={25} style={{ color: 'white' }} /> : 'Upgrade To Trial'}
                                </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Typography>
                <Typography variant="h5">
                    {getSubscriptionText()}
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Return Credits" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {userDetails.returnCredits}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                              size="large"
                              color="primary"
                              variant="contained"
                              fullWidth
                              onClick={() => setRefillDialogOpen(true)}
                            >
                                Edit
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Default Locker" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {userDetails.lockerName}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Active Returns" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {userDetails.activeReturns}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Total Returns" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {userDetails.totalReturns}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => row._id}
                      classes={{ root: classes.gridHeader }}
                      columns={columns}
                      checkboxSelection={false}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      autoHeight
                      pageSize={5}
                      rows={userDetails.returns}
                      onRowClick={(row) => history.push(`/return/${row.id}`)}
                    />
                </Grid>
            </Grid>
            <InputDialog
              open={refillDialogOpen}
              setOpen={setRefillDialogOpen}
              message="How many return credits would you like the user to have?"
              title="Return Credits"
              placeholder={userDetails.returnCredits.toString()}
              successCallback={(data: string) => updateReturnCredits(data)}
            />
            <ConfirmDialog
              open={showConfirmUpgrade}
              setOpen={setShowConfirmUpgrade}
              message="Are you sure you want to upgrade this user to a free trial?"
              title="Upgrade To Free Trial"
              successCallback={() => upgradeToFreeTrial()}
            />
        </>
    )
}

export default UserDetails;
