import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import FeedbackData from '../shared/types/FeedbackData';
import { feedbackActions } from '../Store/Reducers/feedbackReducer';

interface feedbackServiceType {
    getFeedbackData: () => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useFeedbackService: () => feedbackServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getFeedbackData = async () => {
        const response = await fetch(`${apiUrl}/api/feedback`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<FeedbackData[]>;

        if (results.success) {
            dispatch({
                type: feedbackActions.LoadState,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    return { getFeedbackData };
};

export default useFeedbackService;
