import { DropDownOption } from '../shared/types/DropDownResponse';

function getDateDropDown(): DropDownOption<number>[] {
    return [
        {
            value: 0,
            text: '12:00 AM'
        },
        {
            value: 1,
            text: '1:00 AM'
        },
        {
            value: 2,
            text: '2:00 AM'
        },
        {
            value: 3,
            text: '3:00 AM'
        },
        {
            value: 4,
            text: '4:00 AM'
        },
        {
            value: 5,
            text: '5:00 AM'
        },
        {
            value: 6,
            text: '6:00 AM'
        },
        {
            value: 7,
            text: '7:00 AM'
        },
        {
            value: 8,
            text: '8:00 AM'
        },
        {
            value: 9,
            text: '9:00 AM'
        },
        {
            value: 10,
            text: '10:00 AM'
        },
        {
            value: 11,
            text: '11:00 AM'
        },
        {
            value: 12,
            text: '12:00 PM'
        },
        {
            value: 13,
            text: '1:00 PM'
        },
        {
            value: 14,
            text: '2:00 PM'
        },
        {
            value: 15,
            text: '3:00 PM'
        },
        {
            value: 16,
            text: '4:00 PM'
        },
        {
            value: 17,
            text: '5:00 PM'
        },
        {
            value: 18,
            text: '6:00 PM'
        },
        {
            value: 19,
            text: '7:00 PM'
        },
        {
            value: 20,
            text: '8:00 PM'
        },
        {
            value: 21,
            text: '9:00 PM'
        },
        {
            value: 22,
            text: '10:00 PM'
        },
        {
            value: 23,
            text: '11:00 PM'
        }
    ]
}

export default getDateDropDown;
