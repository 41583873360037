import { Socket } from 'socket.io-client';
import setupConfigurationListeners from './Events/configuration';
import setupHistoryListeners from './Events/history';
import setupLockListeners from './Events/lock';

namespace Events {
    let eventsConfigured = false;

    export function configureEvents(socket: Socket) {
        if (!eventsConfigured) {
            eventsConfigured = true;

            // Setup Listeners
            setupConfigurationListeners(socket);
            setupHistoryListeners(socket);
            setupLockListeners(socket);

            // Debug Listeners
            if (process.env.NODE_ENV === 'development') {
                socket.onAny((...events) => {
                    console.log('Debug Output', events);
                });
            }

            // Load initial data
            socket.emit('configuration.sync');
            socket.emit('configuration.locks');
        }
    }
}

export default Events;
