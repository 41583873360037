import { CircularProgress, makeStyles, Typography, Button, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import shortDateFormat from '../../Helpers/DateFormatter';
import useDriverService from '../../Services/useDriverService';
import DriverData, { DriverLogData } from '../../shared/types/DriverData';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    }
}));

const DriverLogDetails: React.FunctionComponent = () => {
    const { driverId, logId } = useParams<{driverId: string, logId: string}>();
    const classes = useStyles();
    const history = useHistory();

    const drivers = useSelector((store: RootState) => store.driver);

    const [isLoading, setIsLoading] = useState(true);
    const driverService = useDriverService();
    const [driver, setDriver] = useState<DriverData | null>(null);
    const [driverLog, setDriverLog] = useState<DriverLogData | null>(null);

    useEffect(() => {
        const curDriver = drivers.find((driver) => driver.id === driverId);
        if (curDriver) {
            setDriver(curDriver);
            const driverLog = curDriver?.driverLogs?.find((log) => log.id === logId);
            if (driverLog) {
                setDriverLog(driverLog);
            } else {
                history.push('/driver');
            }
        } else {
            history.push('/driver');
        }
    }, [drivers, driverId]);

    useEffect(() => {
        setIsLoading(true);
        async function loadDriverLog() {
            try {
                await driverService.getDriverLogs(driverId);
            } finally {
                setIsLoading(false);
            }
        }
        loadDriverLog();
    }, []);

    const getMapsUrl = (lat: number, lng: number) => {
        const url = `https://www.google.com/maps/embed/v1/place?q=${lat}%20${lng}&key=AIzaSyAiAMps2K8Oz0yF3riesgXhKjf7kbOrK8w`;
        return url;
    };

    const renderBoolean = (value: boolean) => (value ? '✔️' : '❌');

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h4" color="inherit">
                    {`Driver Log: ${driver?.name}`}
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      color="primary"
                      onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Unlock Time" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {driverLog?.unlockTimestamp ? shortDateFormat(driverLog?.unlockTimestamp) : 'N/A'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Packages" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {driverLog?.scannedPackages ? driverLog?.scannedPackages.length : 'N/A'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Printer Test" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {driverLog?.testPrint !== undefined ? renderBoolean(driverLog?.testPrint) : 'N/A'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {
                            driverLog?.dropLocations?.map((location) => (
                                <Grid item lg={3} md={6} xs={12} key={location.name}>
                                    <Card
                                      className={classes.statCard}
                                      style={{ cursor: location.image ? 'pointer' : 'default' }}
                                      onClick={() => location.image && window.open(location.image, '_blank')}
                                    >
                                        <CardHeader title={`${location.name} Image`} />
                                        <CardContent>
                                            <Typography variant="h4" className={classes.boldText}>
                                                {location.image ? '✔️' : '❌'}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                {
                    driverLog?.dropLocations?.map((location) => (
                        <Grid item md={6} xs={12} key={location.name}>
                            <Card className={classes.statCard}>
                                <CardHeader title={`${location.name} Drop Location`} />
                                <CardContent>
                                    {
                                !location.location || location.location.length !== 3
                            ? (
                                <Typography variant="h4" className={classes.boldText}>
                                    N/A
                                </Typography>
                            )
                            : (
                                <iframe
                                  title="Drop Location"
                                // eslint-disable-next-line max-len
                                  src={getMapsUrl(location.location[0], location.location[1])}
                                  width="400"
                                  height="200"
                                  loading="lazy"
                                />
                            )
                            }
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
                <Grid item xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Labels Scanned" />
                        <CardContent>
                            {
                                driverLog?.scannedPackages?.map((scannedCode) => (
                                    <Typography variant="h5" className={classes.boldText} key={scannedCode}>
                                        {scannedCode}
                                    </Typography>
                                ))
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default DriverLogDetails;
