import { useDispatch } from 'react-redux';
import SocketClient from '../Helpers/SocketClient';
import ApiResponse from '../shared/types/ApiResponse';
import { accessUserActions } from '../Store/Reducers/accessUserReducer';
import { AccessUser } from '../types/AccessUser';

interface acsAccessUserServiceType {
    saveAccessUser: (accessUser: AccessUser) => Promise<boolean>,
    updateAccessUser: (accessUser: AccessUser) => Promise<boolean>,
    deleteAccessUser: (accessUserId: string) => Promise<boolean>,
}

const apiUrl = process.env.REACT_APP_ACS_URL;

const useACSAccessUserService: () => acsAccessUserServiceType = () => {
    const dispatch = useDispatch();

    const setToken = async (): Promise<string> => {
        const socketConnection = await SocketClient.getInstance();
        if (socketConnection && socketConnection.connection) {
            const auth = socketConnection.connection.auth as any;
            const token = auth.token as string;
            return token;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000))

        return setToken();
    };

    const saveAccessUser = async (accessUser: AccessUser) => {
        const response = await fetch(`${apiUrl}/api/accessuser`, {
            method: 'POST',
            body: JSON.stringify(accessUser),
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessUser>;

        if (results.success) {
            return true;
        }
        return false;
    };

    const updateAccessUser = async (accessUser: AccessUser) => {
        const response = await fetch(`${apiUrl}/api/accessuser/${accessUser._id}`, {
            method: 'PATCH',
            body: JSON.stringify(accessUser),
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessUser>;

        if (results.success) {
            return true;
        }
        return false;
    };

    const deleteAccessUser = async (accessUserId: string) => {
        const response = await fetch(`${apiUrl}/api/accessUser/${accessUserId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AccessUser>;

        if (results.success) {
            dispatch({ type: accessUserActions.RemoveState, payload: results.data?._id });
            return true;
        }
        return false;
    };

    return { saveAccessUser, updateAccessUser, deleteAccessUser };
};

export default useACSAccessUserService;
