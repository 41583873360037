import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import MobileNavigation from './MobileNavigation';
import Sidebar from './Sidebar';

const Navigation: React.FunctionComponent = () => {
    const isMobile = useMediaQuery('(max-width:1000px)');

    return (
        <>
            {
                isMobile ? <MobileNavigation /> : <Sidebar />
            }
        </>
    );
}

export default Navigation;
