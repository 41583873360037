/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import { AccessUser } from '../../types/AccessUser';

const initialState: AccessUser[] = [];

export enum accessUserActions {
    LoadState = 'AccessUserActions_LoadState',
    AddUser = 'AccessUserActions_AddUser',
    RemoveState = 'AccessUserActions_RemoveState'
}

export default function accessUserReducer(state = initialState, action: AnyAction) {
    if (action.type === accessUserActions.LoadState) {
        const accessUsers: AccessUser[] = action.payload;
        return accessUsers;
    }

    if (action.type === accessUserActions.AddUser) {
        const accessUser: AccessUser = action.payload;
        // Add user if it does not exist
        if (!state.find((user) => user._id === accessUser._id)) {
            return [...state, accessUser];
        }

        return state;
    }

    if (action.type === accessUserActions.RemoveState) {
        const accessUserId: string = action.payload;
        return state.filter((user) => user._id !== accessUserId);
    }

    return state;
}
