import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import AlertData from '../shared/types/AlertData';
import { alertActions } from '../Store/Reducers/alertReducer';

interface alertServiceType {
    getAlertData: () => Promise<boolean>
    createAlert: (alertData: AlertData) => Promise<boolean>
    deleteAlert: (alertId: string) => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useAlertService: () => alertServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getAlertData: () => Promise<boolean> = async () => {
        const response = await fetch(`${apiUrl}/api/alert`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AlertData[]>;

        if (results.success) {
            dispatch({
                type: alertActions.LoadState,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    const createAlert: (alertData: AlertData) => Promise<boolean> = async (alertData: AlertData) => {
        const response = await fetch(`${apiUrl}/api/alert`, {
            method: 'POST',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(alertData)
        });

        const results = await response.json() as ApiResponse<AlertData>;

        if (results.success) {
            dispatch({
                type: alertActions.AddAlert,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    const deleteAlert = async (id: string) => {
        const response = await fetch(`${apiUrl}/api/alert/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<AlertData>;

        if (results.success) {
            dispatch({
                type: alertActions.DeleteAlert,
                payload: results.data?._id
            });

            return true;
        }
        return false;
    };

    return { getAlertData, createAlert, deleteAlert };
};

export default useAlertService;
