import { Socket } from 'socket.io-client';
import mainStore from '../../Store/mainStore';
import { accessLockActions } from '../../Store/Reducers/accessLockReducer';

const prefix = 'lock';

function setupLockListeners(socket: Socket) {
    socket.on(`${prefix}.lock`, async (lockId: string) => {
        // Send the lock event to all lockers
        mainStore.dispatch({
            type: accessLockActions.LockLock,
            payload: lockId
        });
    });

    socket.on(`${prefix}.unlock`, async (lockId: string) => {
        // Send the unlock event to all lockers
        mainStore.dispatch({
            type: accessLockActions.UnlockLock,
            payload: lockId
        });
    });
}

export default setupLockListeners;
