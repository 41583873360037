import io, { Socket } from 'socket.io-client'
import { authenticationServiceType } from '../Services/useAuthenticationService';

class SocketClient {
    private static instance: SocketClient;

    public connection?: Socket;

    constructor() {
        if (SocketClient.instance && this.connection) {
            return SocketClient.instance;
        }

        SocketClient.instance = this;
    }

    // Passing in the authentication service to the socket client
    // sets up the socket connection if its not already connected
    static async getInstance(authService?: authenticationServiceType): Promise<SocketClient> {
        if (!SocketClient.instance && authService) {
            SocketClient.instance = new SocketClient();

            const authToken = await authService.getACSToken();

            if (!authToken) {
                throw new Error('Could not get authentication token');
            }

            SocketClient.instance.connection = io(process.env.REACT_APP_ACS_URL as string, {
                reconnection: true,
                auth: {
                    token: `Bearer ${authToken}`
                },
                autoConnect: false,
                reconnectionDelayMax: 1000
            });

            const currentInstance = SocketClient.instance;

            const connectionPromise = new Promise<boolean>((resolve, reject) => {
                const timeout = setTimeout(() => {
                    console.log('Could not connect to socket. Reason: Timeout');
                    reject(new Error('Could not connect to socket. Reason: Timeout'));
                }, 5000);

                currentInstance.connection?.on('connect', () => {
                    clearTimeout(timeout);
                    console.log('Connected to socket');
                    resolve(true);
                });

                currentInstance.connection?.on('connect_error', (err) => {
                    clearTimeout(timeout);
                    console.log(`Socket connection failed: ${err}`);

                    if (err.message === 'Authentication Error') {
                        authService.getACSToken().then((token) => {
                            if (SocketClient.instance.connection) {
                                SocketClient.instance.connection.auth = {
                                    token: `Bearer ${token}`
                                };
                            }
                        });
                    }

                    reject(new Error(`Socket connection failed: ${err}`));
                });

                if (currentInstance.connection) {
                    currentInstance.connection.connect();
                } else {
                    clearTimeout(timeout);
                    console.log('Socket connection failed: No connection');
                    reject(new Error('Socket connection failed'));
                }
            });

            const results = await connectionPromise;
            if (!results) {
                throw new Error('Socket connection failed');
            }
        }

        return SocketClient.instance;
    }
}

export default SocketClient;
