import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import LockerData from '../shared/types/LockerData';
import { RLLockData } from '../shared/types/RLLocksResponse';
import { lockerActions } from '../Store/Reducers/lockerReducer';
import LockerIdentifier from '../types/LockerIdentifier';

interface lockerServiceType {
    getLockers: () => Promise<boolean>,
    lockLock: (id: string) => Promise<ApiResponse<RLLockData>>,
    unlockLock: (id: string) => Promise<ApiResponse<RLLockData>>,
    resetPackageCount: (lockerId: string, slotLockId: string) => Promise<boolean>
    refillLabels: (lockerId: string, labelCount: number) => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useLockerService: () => lockerServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    async function getLockers(): Promise<boolean> {
        const response = await fetch(`${apiUrl}/api/locker`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        if (response.ok) {
            const results = await response.json() as ApiResponse<LockerData[]>;
            if (results.success) {
                dispatch({
                    type: lockerActions.LoadState,
                    payload: results.data
                });
                return true;
            }
        }

        return false;
    }

    async function lockLock(id: string): Promise<ApiResponse<RLLockData>> {
        const response = await fetch(`${apiUrl}/api/locker/${id}/lock`, {
            method: 'PUT',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<RLLockData>;

        return results;
    }

    async function unlockLock(id: string): Promise<ApiResponse<RLLockData>> {
        const response = await fetch(`${apiUrl}/api/locker/${id}/unlock`, {
            method: 'PUT',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<RLLockData>;

        return results;
    }

    async function resetPackageCount(lockerId: string, slotLockId: string): Promise<boolean> {
        const response = await fetch(`${apiUrl}/api/locker/${lockerId}/${slotLockId}/packages/reset`, {
            method: 'PATCH',
            headers: {
                Authorization: await setToken()
            }
        });

        if (response.ok) {
            dispatch({
                type: lockerActions.ResetPackages,
                payload: {
                    lockerId,
                    slotLockId
                } as LockerIdentifier
            })
        }

        return response.ok;
    }

    async function refillLabels(lockerId: string, labelCount: number) : Promise<boolean> {
        console.log(`${lockerId} ${labelCount}`);
        const response = await fetch(`${apiUrl}/api/locker/${lockerId}/labels`, {
            method: 'PATCH',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                labelCount
            })
        });

        if (response.ok) {
            dispatch({
                type: lockerActions.RefillLabels,
                payload: {
                    lockerId,
                    labelCount
                }
            })
        }

        return response.ok;
    }

    return { getLockers, lockLock, unlockLock, resetPackageCount, refillLabels };
};

export default useLockerService;
