/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import AlertData from '../../shared/types/AlertData';

type alertState = {
    alerts: AlertData[]
}

const initialState: alertState = {
    alerts: []
};

export enum alertActions {
    LoadState = 'AlertActions_LoadState',
    AddAlert = 'AlertActions_AddAlert',
    DeleteAlert = 'AlertActions_DeleteAlert'
}

export default function alertReducer(state: alertState = initialState, action: AnyAction): alertState {
    if (action.type === alertActions.LoadState) {
        const currentAlerts: AlertData[] = action.payload;
        return {
            alerts: currentAlerts
        };
    }

    if (action.type === alertActions.AddAlert) {
        const currentDiscount: AlertData = action.payload;
        return {
            alerts: [...state.alerts, currentDiscount]
        };
    }

    if (action.type === alertActions.DeleteAlert) {
        const currentAlertId: string = action.payload;

        return {
            alerts: state.alerts.filter((alert) => alert._id !== currentAlertId)
        }
    }

    return state;
}
