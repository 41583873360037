import { Socket } from 'socket.io-client';
import { accessLockActions } from '../../Store/Reducers/accessLockReducer';
import { accessUserActions } from '../../Store/Reducers/accessUserReducer';
import { AccessLock } from '../../types/AccessLock';
import { AccessUser } from '../../types/AccessUser';
import mainStore from '../../Store/mainStore';

const prefix = 'configuration';

function setupConfigurationListeners(socket: Socket) {
    socket.on(`${prefix}.locks`, async (accessLocks: AccessLock[]) => {
        mainStore.dispatch({
            type: accessLockActions.LoadState,
            payload: accessLocks
        });
    });

    socket.on(`${prefix}.sync`, async (accessUsers: AccessUser[]) => {
        mainStore.dispatch({
            type: accessUserActions.LoadState,
            payload: accessUsers
        });
    });
}

export default setupConfigurationListeners;
