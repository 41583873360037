import { Socket } from 'socket.io-client';
import mainStore from '../../Store/mainStore';
import { accessHistoryActions } from '../../Store/Reducers/accessHistoryReducer';
import { AccessHistory } from '../../types/AccessHistory';

const prefix = 'history';

function setupHistoryListeners(socket: Socket) {
    socket.on(`${prefix}.new`, async (accessHistory: AccessHistory) => {
        // Send the lock event to all lockers
        mainStore.dispatch({
            type: accessHistoryActions.AddState,
            payload: accessHistory
        });
    });
}

export default setupHistoryListeners;
