import { Card, CardContent, CardHeader, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Past30DaysChart from '../../Components/Charts/Past30DaysChart';
import SubscriptionBreakdownChart from '../../Components/Charts/SubscriptionBreakdownChart';
import useDashboardService from '../../Services/useDashboardService';
import useReturnService from '../../Services/useReturnService';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    superscript: {
        fontStyle: 'italic',
        display: 'inline',
        position: 'relative',
        top: '-1em'
    },
    greenText: {
        color: colors.lightGreen
    }
}));

const Dashboard: React.FunctionComponent = () => {
    const classes = useStyles();

    const dashboardStats = useSelector((store: RootState) => store.dashboard);

    const dashboardService = useDashboardService();
    const returnService = useReturnService();

    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        async function loadDashboardData() {
            await dashboardService.getDashboardData();
            await returnService.getReturnData();
            setLoading(false);
        }

        loadDashboardData();
    }, []);

    const get30DayReturnCount = () : number => {
        let totalCount = 0;
        dashboardStats.returnsPastMonth.forEach((amount) => {
            totalCount += amount;
        });
        return totalCount;
    }

    const get1DayReturnCount = () : number => dashboardStats.returnsPastMonth[dashboardStats.returnsPastMonth.length - 1]

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                Dashboard
            </Typography>
            <Grid container spacing={5}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardHeader style={{ textAlign: 'center' }} title="Returns Dropped Off Over Past 30 Days" />
                        <CardContent>
                            <Past30DaysChart />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardHeader style={{ textAlign: 'center' }} title="User Subscriptions" />
                        <CardContent>
                            <SubscriptionBreakdownChart />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Lockers Online" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {`${dashboardStats.lockersOnline}/${dashboardStats.totalLockers}`}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="Total Users" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {
                                    dashboardStats.subscriptions.cartDealer
                                    + dashboardStats.subscriptions.scoopreme
                                    + dashboardStats.subscriptions.freeTrial
                                }
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Card className={classes.statCard}>
                        <CardHeader title="30 Day Return Count" />
                        <CardContent>
                            <Typography variant="h4" className={classes.boldText}>
                                {get30DayReturnCount()}
                                <Typography variant="subtitle1" className={classes.superscript}>
                                    &nbsp;
                                    <span className={classes.greenText}>{`+${get1DayReturnCount()}`}</span>
                                    &nbsp;1D
                                </Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;
