import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import DiscountData from '../shared/types/DiscountData';
import { discountActions } from '../Store/Reducers/discountReducer';

interface discountServiceType {
    getDiscountData: () => Promise<boolean>
    createDiscount: (discountData: DiscountData) => Promise<boolean>
    updateDiscount: (discountData: DiscountData) => Promise<boolean>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useDiscountService: () => discountServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getDiscountData: () => Promise<boolean> = async () => {
        const response = await fetch(`${apiUrl}/api/discount`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<DiscountData[]>;

        if (results.success) {
            dispatch({
                type: discountActions.LoadState,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    const createDiscount: (discountData: DiscountData) => Promise<boolean> = async (discountData: DiscountData) => {
        const response = await fetch(`${apiUrl}/api/discount`, {
            method: 'POST',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(discountData)
        });

        const results = await response.json() as ApiResponse<DiscountData>;

        if (results.success) {
            dispatch({
                type: discountActions.AddDiscount,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    const updateDiscount: (discountData: DiscountData) => Promise<boolean> = async (discountData: DiscountData) => {
        const response = await fetch(`${apiUrl}/api/discount`, {
            method: 'PATCH',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(discountData)
        });

        const results = await response.json() as ApiResponse<DiscountData[]>;

        if (results.success) {
            dispatch({
                type: discountActions.UpdateDiscount,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    return { getDiscountData, createDiscount, updateDiscount };
};

export default useDiscountService;
