import { useAuth0 } from '@auth0/auth0-react';
import ApiResponse from '../shared/types/ApiResponse';

export interface authenticationServiceType {
    isLoggedIn: () => Promise<boolean>
    getACSToken: () => Promise<string>
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useAuthenticationService: () => authenticationServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const isLoggedIn: () => Promise<boolean> = async () => {
        const response = await fetch(`${apiUrl}/api/authentication`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<boolean>;

        if (results.success) {
            return true;
        }
        return false;
    };

    const getACSToken = async () => {
        const response = await fetch(`${apiUrl}/api/authentication/acs_token`, {
            method: 'POST',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<string>;

        if (results.success && results.data) {
            return results.data;
        }

        return '';
    };

    return { isLoggedIn, getACSToken };
};

export default useAuthenticationService;
