import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import ApiResponse from '../shared/types/ApiResponse';
import DriverData, { DriverLogData } from '../shared/types/DriverData';
import { driverActions } from '../Store/Reducers/driverReducer';

interface driverServiceType {
    getDriverData: () => Promise<boolean>
    getDriverLogs: (driverId: string) => Promise<boolean>,
    saveDriverData: (driverData: DriverData) => Promise<boolean>,
    updateDriverPin: (driverId: string, newPin: string) => Promise<boolean>,
    deleteDriver: (driverId: string) => Promise<boolean>,
    updateDriverData: (driverData: DriverData) => Promise<boolean>,
}

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const useDriverService: () => driverServiceType = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const setToken = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        return `Bearer ${token}`;
    };

    const getDriverData = async () => {
        const response = await fetch(`${apiUrl}/api/driver`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<DriverData[]>;

        if (results.success) {
            dispatch({
                type: driverActions.LoadDrivers,
                payload: results.data
            });

            return true;
        }
        return false;
    };

    const getDriverLogs = async (driverId: string) => {
        const response = await fetch(`${apiUrl}/api/driver/${driverId}`, {
            method: 'GET',
            headers: {
                Authorization: await setToken()
            }
        });

        const results = await response.json() as ApiResponse<DriverLogData[]>;

        if (results.success) {
            dispatch({
                type: driverActions.LoadDriverLogs,
                payload: {
                    driverId,
                    data: results.data
                }
            });

            return true;
        }
        return false;
    };

    const saveDriverData = async (driverData: DriverData) => {
        const response = await fetch(`${apiUrl}/api/driver`, {
            method: 'POST',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(driverData)
        });

        const results = await response.json() as ApiResponse<DriverData>;

        if (results.success) {
            dispatch({
                type: driverActions.SaveDriverData,
                payload: results.data
            });
            return true;
        }

        return false;
    };

    const updateDriverData = async (driverData: DriverData) => {
        const response = await fetch(`${apiUrl}/api/driver`, {
            method: 'PATCH',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(driverData)
        });

        const results = await response.json() as ApiResponse<DriverData>;

        if (results.success) {
            dispatch({
                type: driverActions.UpdateDriverData,
                payload: results.data
            });
            return true;
        }

        return false;
    };

    const updateDriverPin = async (driverId: string, pin: string) => {
        const response = await fetch(`${apiUrl}/api/driver/${driverId}`, {
            method: 'PATCH',
            headers: {
                Authorization: await setToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ pin })
        });
        const results = await response.json() as ApiResponse<DriverData>;
        if (results.success) {
            return true;
        }
        return false;
    };

    const deleteDriver = async (driverId: string) => {
        const response = await fetch(`${apiUrl}/api/driver/${driverId}`, {
            method: 'DELETE',
            headers: {
                Authorization: await setToken()
            }
        });
        const results = await response.json() as ApiResponse<DriverData>;
        if (results.success) {
            dispatch({
                type: driverActions.DeleteDriverData,
                payload: results.data
            });
            return true;
        }
        return false;
    };

    return { getDriverData, getDriverLogs, saveDriverData, updateDriverPin, deleteDriver, updateDriverData };
};

export default useDriverService;
