import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AlertDialog from '../../Components/Dialogs/AlertDialog';
import GridExportBar from '../../Components/Grid/GridExportBar';
import useAlertService from '../../Services/useAlertService';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: '40px'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const AlertDash: React.FunctionComponent = () => {
    const classes = useStyles();

    const alertService = useAlertService();
    const alertStore = useSelector((store: RootState) => store.alert);

    const [isLoading, setIsLoading] = useState(true);
    const [showAlertDialog, setShowAlertDialog] = useState(false);

    const deleteAlert = async (id: string) => {
        await alertService.deleteAlert(id);
    }

    const columns: GridColDef[] = [
        {
            field: 'lockerId',
            headerName: 'Locker',
            flex: 0.5
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 0.5
        },
        {
            field: 'printerError',
            headerName: 'Is Printer Error',
            flex: 0.3
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 0.5,
            renderCell: ({ id, getValue }: GridCellParams) => {
                const currentAlertId = getValue(id, '_id') as string;

                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => deleteAlert(currentAlertId)}
                        >
                            Delete
                        </Button>
                    </div>
                )
            },
            valueFormatter: () => ''
        }
    ];

    useEffect(() => {
        async function loadAlerts() {
            await alertService.getAlertData();
            setIsLoading(false);
        }

        loadAlerts();
    }, []);

    return (
        <div>
            <Typography variant="h3" className={classes.root}>
                Alerts
                <Button
                  style={{ float: 'right' }}
                  variant="contained"
                  color="primary"
                  onClick={() => setShowAlertDialog(true)}
                >
                    New Alert
                </Button>
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                      classes={{ root: classes.gridHeader }}
                      getRowId={(row) => row._id}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={isLoading}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      pageSize={10}
                      rows={alertStore && alertStore.alerts ? alertStore.alerts : []}
                    />
                </Grid>
            </Grid>
            <AlertDialog
              open={showAlertDialog}
              setOpen={setShowAlertDialog}
            />
        </div>
    );
};

export default AlertDash;
