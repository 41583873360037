import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridSortItem, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import GridExportBar from '../../Components/Grid/GridExportBar';
import shortDateFormat from '../../Helpers/DateFormatter';
import useReturnService from '../../Services/useReturnService';
import { ReturnHistory } from '../../shared/types/ReturnDetail';
import { RootState } from '../../Store/mainStore';
import colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const columns: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Label',
        flex: 0.3,
        renderCell: ({ id, getValue }: GridRenderCellParams) => {
            const returnLabelId = getValue(id, 'returnLabel') as string
            if (returnLabelId && returnLabelId !== 'none') {
                return '✔️';
            }
            return '❌';
        },
        valueFormatter: ({ getValue, id }: GridValueFormatterParams) => {
            const returnLabelId = getValue(id, 'returnLabel') as string
            if (returnLabelId && returnLabelId !== 'none') {
                return 'Yes';
            }
            return 'No';
        }
    },
    {
        field: 'returnTitle',
        headerName: 'Title',
        flex: 0.5
    },
    {
        field: 'ownedByEmail',
        headerName: 'User',
        flex: 0.5
    },
    {
        field: 'created',
        headerName: 'Created',
        flex: 0.5,
        renderCell: ({ id, getValue }: GridCellParams) => {
            const createdDate = getValue(id, 'created') as string
            return shortDateFormat(new Date(createdDate));
        }
    },
    {
        field: 'updatedAt',
        headerName: 'Last Updated',
        flex: 0.5,
        renderCell: ({ id, getValue }: GridCellParams) => {
            const updatedDate = getValue(id, 'updatedAt') as string
            return shortDateFormat(new Date(updatedDate));
        }
    },
    {
        field: 'category',
        headerName: 'Category',
        flex: 0.4
    },
    {
        field: 'returnValue',
        headerName: 'Value',
        flex: 0.4,
        valueFormatter: ({ getValue, id }: GridValueFormatterParams) => {
            const returnValue = getValue(id, 'returnValue') as number
            if (returnValue) {
                return returnValue;
            }
            return '';
        }
    },
    {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 0.5
    },
    {
        field: 'carrier',
        headerName: 'Carrier',
        flex: 0.4
    },
    {
        field: 'Status',
        headerName: 'Status',
        flex: 0.5,
        valueGetter: ({ id, getValue }: GridValueGetterParams) => {
            let history = getValue(id, 'history') as ReturnHistory[];
            history = history?.filter((h) => h.status !== 'Other');
            history.sort((a, b) => new Date(b.timestamp)?.getTime() - new Date(a.timestamp)?.getTime());
            return history[0].status;
        }
    },
    {
        field: 'trackingNumber',
        headerName: 'Tracking #',
        flex: 0.5
    }
];

const ReturnDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const returns = useSelector((store: RootState) => store.return.returns);
    const returnService = useReturnService();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [curPage, setCurPage] = useState(0);
    const [tableSort, setTableSort] = useState<GridSortItem[]>([{ field: 'created', sort: 'desc' }]);

    useEffect(() => {
        async function loadReturnData() {
            await returnService.getReturnData();
            setLoading(false);

            const queryParams = new URLSearchParams(window.location.search);
            const page = queryParams.get('page');
            if (page) {
                setCurPage(parseInt(page, 10));
            }
        }

        loadReturnData();
    }, []);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                Returns
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => row._id}
                      classes={{ root: classes.gridHeader }}
                      columns={columns}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      checkboxSelection={false}
                      autoHeight
                      sortModel={tableSort}
                      onSortModelChange={(sortModel) => setTableSort(sortModel)}
                      loading={loading}
                      pageSize={10}
                      rows={returns}
                      onRowClick={(selectedRow) => {
                        history.push(`/return/${selectedRow.getValue(selectedRow.id, '_id')}`)
                      }}
                      page={curPage}
                      onPageChange={(page) => {
                        setCurPage(page);

                        const urlParams = new URLSearchParams({
                            page: page.toString()
                        });

                        history.replace({
                            pathname: window.location.pathname,
                            search: urlParams.toString()
                        })
                      }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ReturnDash;
