import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { ReactFragment, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useSelector } from 'react-redux';
import colors from '../../Styles/Colors';
import { RootState } from '../../Store/mainStore';
import GridExportBar from '../../Components/Grid/GridExportBar';
import SocketClient from '../../Helpers/SocketClient';
import { AccessLock } from '../../types/AccessLock';
import AccessLockDialog from '../../Components/Dialogs/AccessLockDialog';
import ConfirmDialog from '../../Components/Dialogs/ConfirmDialog';
import useACSAccessLockService from '../../Services/useACSAccessLockService';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(5)
    },
    statCard: {
        textAlign: 'center',
        height: '100%',
        width: '100%'
    },
    boldText: {
        fontWeight: 'bold'
    },
    gridHeader: {
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiDataGrid-iconSeparator': {
            color: colors.lightGray
        }
    }
}));

const AccessLockDash: React.FunctionComponent = () => {
    const classes = useStyles();
    const accessLocks = useSelector((store: RootState) => store.accessLock);

    const acsAccessLockService = useACSAccessLockService();

    const [locksLoading, setLocksLoading] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedAccessLock, setSelectedAccessLock] = useState<AccessLock | undefined>();

    useEffect(() => {
        // Remove loading lock from array if state is different
        if (locksLoading.length > 0) {
            locksLoading.forEach((lock, index) => {
                const matchingLock = accessLocks.find((accessLock) => accessLock._id === lock.lockId);
                if (matchingLock) {
                    if (matchingLock.locked !== lock.lockState) {
                        if (lock.timeout) {
                            clearTimeout(lock.timeout);
                        }
                        const newLoadingLocks = locksLoading;
                        newLoadingLocks.splice(index, 1)
                        setLocksLoading(newLoadingLocks);
                    }
                }
            });
        }
    }, [accessLocks]);

    const waitForLockEventChange = (lockId: string, lockState: boolean) => {
        const timeout = setTimeout(() => {
            const newLocksLoading = locksLoading.filter((lock) => lock.lockId !== lockId);
            setLocksLoading(newLocksLoading);
        }, 5000);

        setLocksLoading([...locksLoading, { lockId, lockState, timeout }]);
    }

    const columns: GridColDef[] = [
        {
            field: 'isOnline',
            headerName: 'Online',
            flex: 0.2,
            renderCell: ({ getValue, id }: GridRenderCellParams) => {
                const data = getValue(id, 'isOnline') as boolean;
                if (data) {
                    return <FiberManualRecordIcon style={{ color: 'green' }} />;
                }

                return <FiberManualRecordIcon style={{ color: 'red' }} />;
            },
            valueFormatter: ({ getValue, id }: GridValueFormatterParams) => {
                const data = getValue(id, 'isOnline') as boolean;
                if (data) {
                    return 'True'
                }

                return 'False'
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5
        },
        {
            field: 'locked',
            headerName: 'Locked',
            flex: 0.5,
            renderCell: ({ getValue, id }: GridRenderCellParams) => {
                const isOnline = getValue(id, 'isOnline') as boolean;
                const data = getValue(id, 'locked') as boolean;
                if (!isOnline) {
                    return <LockOpenIcon style={{ color: 'grey' }} />;
                }

                if (data) {
                    return <LockIcon style={{ color: 'red' }} />;
                }

                return <LockOpenIcon style={{ color: 'green' }} />;
            },
            valueFormatter: ({ getValue, id }: GridValueFormatterParams) => {
                const data = getValue(id, 'isOnline') as boolean;
                if (data) {
                    return 'True'
                }

                return 'False'
            }
        },
        {
            field: 'lockerId',
            headerName: 'Locker',
            flex: 0.5
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: ({ getValue, id }: GridRenderCellParams) => {
                const lockId = getValue(id, '_id') as string;
                const isOnline = getValue(id, 'isOnline') as boolean;
                const data = getValue(id, 'locked') as boolean;

                let actionButtons: ReactFragment;

                if (!isOnline) {
                    actionButtons = (
                        <Button variant="outlined" disabled>
                            Lock
                        </Button>
                    );
                } else if (data) {
                    actionButtons = (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={async (e) => {
                            e.preventDefault();
                            const client = await SocketClient.getInstance();
                            if (client && client.connection) {
                                waitForLockEventChange(lockId, data);
                                await client.connection.emit('lock.unlock', lockId);
                            }
                          }}
                          disabled={locksLoading.find((lock) => lock.lockId === lockId)}
                        >
                            { locksLoading.find((lock) => lock.lockId === lockId) ? 'Locking...' : 'Unlock' }
                        </Button>
                    )
                } else {
                    actionButtons = (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={async (e) => {
                            e.preventDefault();
                            const client = await SocketClient.getInstance();
                            if (client && client.connection) {
                                waitForLockEventChange(lockId, data);
                                await client.connection.emit('lock.lock', lockId);
                            }
                          }}
                          disabled={locksLoading.find((lock) => lock.lockId === lockId)}
                        >
                            { locksLoading.find((lock) => lock.lockId === lockId) ? 'Unlocking...' : 'Lock' }
                        </Button>
                    )
                }

                return (
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {actionButtons}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedAccessLock(accessLocks.find((accessLock) => accessLock._id === lockId));
                            setShowDialog(true);
                          }}
                        >
                            Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedAccessLock(accessLocks.find((accessLock) => accessLock._id === lockId));
                            setShowDeleteDialog(true);
                          }}
                        >
                            Delete
                        </Button>
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        async function loadAccessLockDetails() {
            const socketConnection = (await SocketClient.getInstance());
            if (socketConnection?.connection && socketConnection.connection.connected) {
                socketConnection.connection.emit('configuration.locks');
            }
            setLoading(false);
        }

        loadAccessLockDetails();
    }, []);

    return (
        <>
            <Typography variant="h3" className={classes.header}>
                {`Access Locks | ${accessLocks.length}  `}
                <Button
                  style={{ float: 'right' }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                            setSelectedAccessLock(undefined);
                            setShowDialog(true)
                      }}
                >
                    New Lock
                </Button>
            </Typography>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <DataGrid
                      getRowId={(row) => row._id}
                      classes={{
                          columnHeader: classes.gridHeader
                      }}
                      components={{
                          Toolbar: GridExportBar
                      }}
                      rows={accessLocks}
                      columns={columns}
                      checkboxSelection={false}
                      autoHeight
                      loading={loading}
                      pageSize={10}
                    />
                </Grid>
            </Grid>
            <AccessLockDialog
              open={showDialog}
              setOpen={setShowDialog}
              editLock={selectedAccessLock}
            />
            <ConfirmDialog
              open={showDeleteDialog}
              setOpen={setShowDeleteDialog}
              title="Delete Access Lock"
              message="Are you sure you want to delete this access lock?"
              successCallback={async () => {
                  if (selectedAccessLock) {
                    await acsAccessLockService.deleteAccessLock(selectedAccessLock._id);
                    setShowDeleteDialog(false);
                  }
                }}
            />
        </>
    );
};

export default AccessLockDash;
