/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import { AccessHistory } from '../../types/AccessHistory';

const initialState: AccessHistory[] = [];

export enum accessHistoryActions {
    LoadState = 'AccessHistoryActions_LoadState',
    AddState = 'AccessHistoryActions_AddState'
}

export default function accessHistoryReducer(state = initialState, action: AnyAction) {
    if (action.type === accessHistoryActions.LoadState) {
        const accessHistories: AccessHistory[] = action.payload;
        return accessHistories;
    }

    if (action.type === accessHistoryActions.AddState) {
        const accessHistory: AccessHistory = action.payload;
        if (!state.find((log) => log._id === accessHistory._id)) {
            return [...state, accessHistory];
        }
    }

    return state;
}
