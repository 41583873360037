import { Button,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Input,
    ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useACSAccessLockService from '../../Services/useACSAccessLockService';
import useDropDownService from '../../Services/useDropDownService';
import { DropDownOption } from '../../shared/types/DropDownResponse';
import { AccessLock } from '../../types/AccessLock';

const AccessLockDialog: React.FunctionComponent<AccessLockDialogProps> = (
    {
        open,
        setOpen,
        editLock
    }: AccessLockDialogProps
) => {
    const [newAccessLock, setNewAccessUser] = useState<AccessLock>({
        _id: '',
        isOnline: false,
        lockTime: 10,
        locked: false,
        lockerId: '',
        name: '',
        position: 1
    });

    const [submitted, setSubmitted] = useState<any>(false);

    const acsLockerService = useACSAccessLockService();
    const dropDownService = useDropDownService();

    const [lockerDropDown, setLockerDropDown] = useState<DropDownOption[] | null>(null);

    // Load the drop down options for the locker and lock
    useEffect(() => {
        async function loadLockerDropDown() {
            const data = await dropDownService.getDropDownData('lockers');
            if (data?.options) {
                setLockerDropDown(data?.options);
            }
        }

        loadLockerDropDown();
    }, []);

    useEffect(() => {
        if (open) {
            setSubmitted(false);
            if (editLock) {
                setNewAccessUser(editLock);
            } else {
                setNewAccessUser({
                    _id: '',
                    isOnline: false,
                    lockTime: 10,
                    locked: false,
                    lockerId: '',
                    name: '',
                    position: 1
                });
            }
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccess = async () => {
        setSubmitted(true);
        if (
            newAccessLock.name
            && newAccessLock.lockerId
            && newAccessLock.lockTime
            && newAccessLock.position
        ) {
            if (editLock) {
                const results = await acsLockerService.updateAccessLock(newAccessLock);
                if (results) {
                    setOpen(false);
                }
            } else {
                const results = await acsLockerService.saveAccessLock(newAccessLock);
                if (results) {
                    setOpen(false);
                }
            }
        }
    };

    return (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          disableBackdropClick
        >
            <DialogTitle>{editLock ? 'Edit Lock' : 'New Lock'}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    <Grid container style={{ maxWidth: '500px' }} spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAccessLock.name)}
                              label="Name"
                              InputLabelProps={{ shrink: true }}
                              value={newAccessLock.name}
                              onChange={(e) => setNewAccessUser({ ...newAccessLock, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAccessLock.lockTime)}
                              label="Auto Lock Time"
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              value={newAccessLock.lockTime}
                              onChange={(e) => setNewAccessUser({ ...newAccessLock, lockTime: parseInt(e.target.value, 10) })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              error={submitted && (!newAccessLock.position)}
                              label="Position"
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              value={newAccessLock.position}
                              onChange={(e) => setNewAccessUser({ ...newAccessLock, position: parseInt(e.target.value, 10) })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <FormLabel
                                  component="legend"
                                  style={{ fontSize: '.8rem' }}
                                >
                                    Locker
                                </FormLabel>
                                <Select
                                  input={<Input />}
                                  fullWidth
                                  error={submitted && (!newAccessLock.lockerId)}
                                  value={newAccessLock.lockerId}
                                  renderValue={(value) => {
                                      if (lockerDropDown) {
                                        const currentLocker = lockerDropDown.find((option) => option.value === value);
                                        return `${currentLocker?.text} - ${currentLocker?.value}`
                                      }
                                      return '';
                                  }}
                                  onChange={(e) => setNewAccessUser({ ...newAccessLock, lockerId: e.target.value as string })}
                                >
                                    {lockerDropDown && lockerDropDown.map((locker) => (
                                        <MenuItem key={locker.value} value={locker.value}>
                                            <ListItemText primary={`${locker.text} - ${locker.value}`} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Close
                </Button>
                <Button onClick={handleCloseSuccess} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AccessLockDialog.defaultProps = {
    editLock: undefined
}

interface AccessLockDialogProps {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    editLock?: AccessLock
}

export default AccessLockDialog;
